import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./DebtISINList.css"; // Import the CSS file for styling

const DebtISINList = () => {
  const [responseData, setResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("REC Limited");
  const [selectedISIN, setSelectedISIN] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [RESPONSE_DATA, SET_RESPONSE_DATA] = useState([]);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "/nse/company-list", {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        });
        setResponseData(response.data);
        setFilteredData(response.data);
        console.log("Data Name:", response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCompanyChange = async (event) => {
    const selectedIndex = event.target.value;
    const selectedValue = responseData[selectedIndex];
    setSelectedCompany(selectedValue);
    console.log(`You selected company: ${selectedValue}`);

    fetchFilteredData(selectedValue, selectedISIN, selectedType);
  };

  const handleISINChange = (event) => {
    const isin = event.target.value;
    setSelectedISIN(isin);
    fetchFilteredData(selectedCompany, isin, selectedType);
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    fetchFilteredData(selectedCompany, selectedISIN, type);
  };

  const fetchFilteredData = async (company, isin, type) => {
    try {
      let url = `${baseUrl}/nse`;
      if (company) {
        url += `/${company}`;
      }

      const response = await axios.get(url, {
        headers: {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      });

      let filteredData = response.data;

      if (isin) {
        filteredData = filteredData.filter((item) => item.isin === isin);
      }

      if (type) {
        filteredData = filteredData.filter((item) => item.type === type);
      }

      SET_RESPONSE_DATA(filteredData);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const DownloadFile = () => {
    const csvRows = [];

    // Add the headers
    const headers = ["ID", "Name", "ISIN", "Details", "Type", "URL"];
    csvRows.push(headers.join(","));

    // Add the data
    RESPONSE_DATA.forEach((item) => {
      const row = [
        item.id,
        item.companyName,
        item.isin,
        item.details,
        item.type,
        item.document,
      ];
      csvRows.push(row.join(","));
    });

    // Create the CSV string
    const csvString = csvRows.join("\n");

    // Create a Blob object and a link element to trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "isin_data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleISINClick = (isin) => {
    navigate(`/d/debt/info?isin=${isin}`);
  };
  //www.nseindia.com/companies-listing/corporate-filings-offer-documents
  https: return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>
          NSE Corporate Filings Offer Documents (Debt)
          <a
            href="https://www.nseindia.com/companies-listing/corporate-filings-offer-documents"
            target="_blank"
          >
            LINK
          </a>{" "}
        </h1>
        {responseData.length > 0 && (
          <>
            <div className="flex">
              <select
                className="clickable isin-filters"
                onChange={handleCompanyChange}
              >
                <option value="">Select Company</option>
                {responseData.map((data, index) => (
                  <option key={index} value={index}>
                    {data}
                  </option>
                ))}
              </select>

              <select
                className="clickable isin-filters"
                onChange={handleISINChange}
              >
                <option value="">Select ISIN</option>
                {[...new Set(RESPONSE_DATA.map((item) => item.isin))].map(
                  (isin, index) => (
                    <option key={index} value={isin}>
                      {isin}
                    </option>
                  )
                )}
              </select>

              <select
                className="clickable isin-filters"
                onChange={handleTypeChange}
              >
                <option value="">Select Type</option>
                {[...new Set(RESPONSE_DATA.map((item) => item.type))].map(
                  (type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  )
                )}
              </select>
              <button className="button" onClick={DownloadFile}>
                Download Csv
              </button>
            </div>
          </>
        )}
      </div>
      <div>
        <h2>Data Table</h2>
        <table className="isin-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>ISIN</th>

              <th>Details</th>
              <th>Type</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {RESPONSE_DATA.map((ITEM, INDEX) => (
              <tr key={INDEX}>
                <td>{ITEM.id}</td>
                <td>{ITEM.companyName}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleISINClick(ITEM.isin);
                    }}
                  >
                    {ITEM.isin}
                  </a>
                </td>
                <td>{ITEM.details}</td>
                <td>{ITEM.type}</td>
                <td>
                  <a
                    href={ITEM.document}
                    style={{ textDecoration: "none", borderRadius: "5px" }}
                  >
                    LINK
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DebtISINList;
