import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import Demo from "../RatingNew";
import DebtISINListCompany from "../nse/DebtISINListCompany.js";
import DebtAnnouncement from "../nse/DebtAnnouncement.js";
import News from "../News.js";
import BondsTradedInCapitalMarket from "../nse/BondsTradedInCapitalMarket.js";
import MoreInfoModal from "../Dashboard/Modal/MoreInfoModal.js";
import TradeRepositoryData from "../nse/TradeRepositoryData.js";
const { TabPane } = Tabs;

const IssuerDashboard = () => {
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("ISIN");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChange = (key) => {
    console.log(key);
    setActiveTab(key);
  };

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          type="card"
          style={{ flex: 1 }}
          tabBarExtraContent={
            <button
              onClick={handleButtonClick}
              className="ant-btn css-dev-only-do-not-override-1okl62o ant-btn-default"
            >
              More Info
            </button>
          }
        >
          <TabPane tab="Ratings" key="1">
            <div>
              <Demo />
            </div>
          </TabPane>
          <TabPane tab="ISIN List" key="2">
            <div>
              <DebtISINListCompany />
            </div>
          </TabPane>
          <TabPane tab="News" key="3">
            <div>
              <News />
            </div>
          </TabPane>
          <TabPane tab="Debt Announcements" key="4">
            <div>
              <DebtAnnouncement />
            </div>
          </TabPane>
          <TabPane tab="Trading Volume" key="5">
            <div>
              <BondsTradedInCapitalMarket />
            </div>
          </TabPane>
          <TabPane tab="Trade Repository" key="6">
            <div>
              <TradeRepositoryData />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <MoreInfoModal visible={isModalVisible} onClose={handleCloseModal} />
    </>
  );
};

export default IssuerDashboard;
