import { Table, Tag } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";

// Convert timestamp to readable format
const formatDate = (timestamp) => {
  return format(new Date(timestamp * 1000), "dd MMM yyyy, hh:mm:ss a");
};

// Columns for the Ant Design Table

const statusColors = {
  paid: "green",
  attempted: "skyblue",
  created: "grey",
};
const columns = [
  {
    title: "Order Id",
    dataIndex: "order_id",
    key: "order_id",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => `₹ ${(amount / 100).toFixed(2)}`,
  },

  {
    title: "Attempts",
    dataIndex: "attempts",
    key: "attempts",
    render: (attempts) => <a>{attempts}</a>, // Replace with actual attempts data if available
  },
  {
    title: "Receipt",
    dataIndex: "receipt",
    key: "receipt",
    render: (receipt) => <a>{receipt}</a>, // Replace with actual receipt if available
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (timestamp) => formatDate(timestamp),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={statusColors[status]}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Tag>
    ),
  },
];

const OrderDetails = () => {
  const [paymentData, setPaymentData] = useState([]);
  const baseUrl = process.env.REACT_APP_RAZORPAY_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "/api/rzp/all/orders", {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("razorpayAuthToken"),
          },
        });
        console.log("Response Data:", response.data);

        // Transform the API response into the desired format
        const transformedData = response.data.map((item, index) => ({
          key: index,
          order_id: item.order.id,
          amount: item.order.amount,
          created_at: item.order.created_at,
          status: item.order.status,
          attempts: item.order.attempts,
          receipt: item.order.receipt,
        }));

        setPaymentData(transformedData);
        console.log("Transformed Data:", transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1 className="my-4">Order Details</h1>
      <Table columns={columns} dataSource={paymentData} />
    </div>
  );
};

export default OrderDetails;
