import { useEffect, useState } from "react";
import axios from "axios";
import { Table, Tabs } from "antd";
import "./NSEDebtPdfData.css";
import { useSearchParams } from "react-router-dom";
import { Pie } from "react-chartjs-2";

const { TabPane: AntTabPane } = Tabs;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const camelCaseToNormal = (str) => {
  return str
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
};

const NSEDebtPdfData = () => {
  const [data, setData] = useState([]);
  const [bondCashFlowData, setBondCashFlowData] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [riskFacorsData, setRiskFactorsData] = useState([]);
  const [scanData, setScanData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [currentRatings, setCurrentRatings] = useState([]);
  const [earlierRatings, setEarlierRatings] = useState([]);
  const [financialConsolidatedData, setFinancialConsolidatedData] = useState(
    []
  );

  const [financialStandaloneData, setFinancialStandaloneData] = useState([]);

  const [creditNoteData, setCreditNoteData] = useState([]);
  const [ratingColumns, setRatingColumns] = useState([]);

  const [searchParams] = useSearchParams();
  const isin = searchParams.get("isin");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      const url = baseUrl + "/nse/get-isin-data/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched data:", response.data);
        if (response.data && typeof response.data === "object") {
          const transformedData = Object.keys(response.data).map((key) => ({
            key,
            name: camelCaseToNormal(key),
            value: response.data[key],
          }));
          setData(transformedData);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };

    const fetchCreditNoteData = async () => {
      const url = baseUrl + "/nse/get-credit-note-by-isin/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched data:", response.data);
        if (response.data && typeof response.data === "object") {
          const transformedData = Object.keys(response.data).map((key) => ({
            key,
            name: camelCaseToNormal(key),
            value: response.data[key],
          }));
          setCreditNoteData(transformedData);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };

    const fetchFinancialData = async () => {
      const allowedKeys = [
        "Liabilities",
        "Assets",
        "Financial Liabilities",
        "Non-Financial Liabilities",
        "PROFIT AND LOSS",
        "Cash Flow",
        "Additional Information",
        "Loans",
        "Impairment on Financial Instruments",
      ];
      const url = baseUrl + "/financial/isin/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched financial data:--", response.data);
        if (response.data) {
          const transformTableData = (tables) => {
            return tables
              .map((table) => {
                const rowData = {
                  key: table.id,
                  name: table.sectionName || "Unnamed Section",
                };
                let allValuesEmpty = true;

                table.yearlyData.forEach((data) => {
                  const value = data.value || "";
                  rowData[data.year] = value;
                  if (value) {
                    allValuesEmpty = false;
                  }
                });

                // Allow the row if sectionName is in allowedKeys or if not all values are empty
                const isAllowedSection = allowedKeys.some((key) =>
                  table.sectionName.includes(key)
                );
                return isAllowedSection || !allValuesEmpty ? rowData : null;
              })
              .filter((row) => row !== null); // Remove null rows
          };

          const standaloneData = transformTableData(
            response.data.standaloneTables
          );
          const consolidatedData = transformTableData(
            response.data.consolidatedTables
          );

          setFinancialStandaloneData(standaloneData);
          setFinancialConsolidatedData(consolidatedData);
        }
      } catch (error) {
        console.error("Error making the financial data request:", error);
      }
    };
    const fetchBondCashFlowData = async () => {
      const url = baseUrl + "/financial/cash-flow/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched data:", response.data);
        if (response.data && typeof response.data === "object") {
          const transformedData = Object.keys(response.data).map((key) => ({
            key,
            name: camelCaseToNormal(key),
            value: response.data[key] ? response.data[key] : "-",
          }));
          setBondCashFlowData(transformedData);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };
    const fetchRiskFactorsData = async () => {
      const url = baseUrl + "/nse/risk/titles/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched data:", response.data);
        if (response.data && typeof response.data === "object") {
          setRiskFactorsData(response.data);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };
    const fetchCreditRatingsData = async () => {
      const url = baseUrl + "/nse/credit-rating-details/isin/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched  ratings data:", response.data);

        if (response.data && typeof response.data === "object") {
          const { creditRatingDetails } = response.data;

          // Filter the data based on the type
          const current = creditRatingDetails.filter(
            (item) => item.type === "current"
          );
          const earlier = creditRatingDetails.filter(
            (item) => item.type === "earlier"
          );

          setCurrentRatings(current);
          setEarlierRatings(earlier);

          // Set columns based on the keys in the response
          if (creditRatingDetails.length > 0) {
            const dynamicColumns = Object.keys(creditRatingDetails[0]).map(
              (key) => ({
                title: key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase()),
                dataIndex: key,
                key,
              })
            );
            setRatingColumns(dynamicColumns);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };
    const fetchScanData = async () => {
      const url = baseUrl + "/scan/details/" + isin;

      try {
        const response = await axios.get(url, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched scan data:", response.data);
        if (response.data && typeof response.data === "object") {
          const formattedScanData = Object.keys(response.data)
            .filter((key) => key !== "isin")
            .map((key, index) => ({
              key: index,
              category: key,
              value: response.data[key]
                ? `${parseFloat(response.data[key])}%`
                : "0%",
            }));

          setScanData(formattedScanData);
          const pieData = {
            labels: formattedScanData.map((item) => item.category),
            datasets: [
              {
                data: formattedScanData.map((item) => parseFloat(item.value)),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9F40"],
              },
            ],
          };

          setPieData(pieData);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };
    fetchData();
    fetchCreditNoteData();
    fetchFinancialData();
    fetchBondCashFlowData();
    fetchRiskFactorsData();
    fetchCreditRatingsData();
    fetchScanData();
  }, [baseUrl, isin]);

  const scanColumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const financialColumns = [
    {
      title: "Particulars",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Year0",
      dataIndex: "Year0",
      key: "Year0",
    },
    {
      title: "Year1",
      dataIndex: "Year1",
      key: "Year1",
    },
    {
      title: "Year2",
      dataIndex: "Year2",
      key: "Year2",
    },
  ];

  return (
    <div className="debt-info">
      {/* <h1>ISIN Information</h1> */}

      <Tabs defaultActiveKey="1" type="card">
        <AntTabPane tab="Summary" key="1">
          <div>
            <h1>SUMMERY SHEET </h1>

            <Table dataSource={data} columns={columns} pagination={false} />
          </div>
        </AntTabPane>
        <AntTabPane tab="Financials" key="2">
          <div>
            <h1>Standalone Basis</h1>
            <Table
              dataSource={financialStandaloneData}
              columns={financialColumns}
              pagination={false}
            />
            <h1 className="my-4">Consolidated Basis</h1>
            <Table
              dataSource={financialConsolidatedData}
              columns={financialColumns}
              pagination={false}
            />
          </div>
        </AntTabPane>
        <AntTabPane tab="Credit Note" key="3">
          <div>
            <h1>CREDIT NOTE</h1>

            <Table
              dataSource={creditNoteData}
              columns={columns}
              pagination={false}
            />
          </div>
        </AntTabPane>
        <AntTabPane tab="Cash Flow" key="4">
          <div>
            <h1>ILLUSTRATION OF BOND CASH FLOW </h1>
            <Table
              dataSource={bondCashFlowData}
              columns={columns}
              pagination={false}
            />
          </div>
        </AntTabPane>
        <AntTabPane tab="Rating Details" key="6">
          <div>
            <h1>RATING DETAILS</h1>
            <h1>Current Ratings</h1>
            <Table
              columns={ratingColumns}
              dataSource={currentRatings}
              rowKey={(record) =>
                record.creditRatingAgencyName + record.creditRatingDate
              } // Assuming these fields uniquely identify a row
              pagination={false}
            />

            <h1 className="my-4">Earlier Ratings</h1>
            <Table
              columns={ratingColumns}
              dataSource={earlierRatings}
              rowKey={(record) =>
                record.creditRatingAgencyName + record.creditRatingDate
              }
              pagination={false}
            />
          </div>
        </AntTabPane>
        <AntTabPane tab="Risk Factors" key="5">
          <div>
            <h1>RISK FACTORS </h1>

            {riskFacorsData.map((riskFactor, index) => (
              <div key={index}>
                <div className="text-lg">{riskFactor.title}</div> <br />
              </div>
            ))}
          </div>
        </AntTabPane>
        <AntTabPane tab="SCAN" key="7">
          <div>
            <h1>Securities Comprehensive Assessment Number (SCAN)</h1>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <Pie data={pieData} />
            </div>
            <Table
              columns={scanColumns}
              dataSource={scanData}
              pagination={false}
            />
          </div>
        </AntTabPane>
      </Tabs>
    </div>
  );
};

export default NSEDebtPdfData;
