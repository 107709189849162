import App from "../../App";
import BlogAvatar from "../../assets/blog-avatar.svg";
import BlogImg from "../../assets/blog_img.svg";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";

const BlogDetails = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div class="p-md-5 fw-medium fs-5">
        <div class="row">
          {/* <div class="col-md-3 vh-100">
            <div class="fs-4 tc-blue text-start">
              <div class="my-5 fw-bold fs-2">Jump to...</div>
              <div class="my-5 cp">Instruction</div>
              <div class="my-5 cp">3 ways to invest your hard-earned money</div>
              <div class="my-5 cp">The Secret</div>
              <div class="my-5 cp">The Catch</div>

              <div class="my-5 cp">Conclusion</div>
            </div>
          </div> */}

          <div class="tc-blue fs-1">
            The Smartest Way for you to invest your money: SimplyBond
          </div>
          <div class="d-flex">
            <div class="justify-content-evenly my-5 d-flex w-50">
              <div class="">
                <img src={BlogAvatar} height="80" width="80" alt="" />
              </div>
              <div>
                <div class="fs-2 fw-bold">John-Doe</div>
                <div class="text-secondary fs-3">CEO</div>
              </div>
            </div>
            <div class="my-5 fs-2 tc-blue fw-bold">
              <li>3 min read</li>
            </div>
          </div>
          <div class="text-center">
            <img src={BlogImg} />
          </div>
          <div class="m-5">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda
            omnis modi adipisci facere, provident ullam placeat officia odit
            porro quod expedita esse distinctio at odio, ad ipsa debitis a
            autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
            saepe labore ipsum possimus iusto reiciendis dolorum! Reiciendis,
            eius nisi ipsa, accusantium enim similique beatae reprehenderit amet
            deserunt in sed et. Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Cumque quo aut pariatur blanditiis, repudiandae
            sequi autem odit deserunt minima. Reprehenderit corporis ex
            assumenda quasi obcaecati voluptate porro perspiciatis libero!
            Voluptatibus? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Facere, velit saepe enim ipsa nemo repellendus, corporis
            accusamus non quae molestiae praesentium! Reprehenderit deserunt eum
            at laudantium quo autem ullam excepturi. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Adipisci, libero sint accusantium hic
            officia veritatis aliquid, sed repellendus dicta atque, veniam cum
            numquam consequatur! Quisquam iusto doloribus aut sint commodi.
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit harum id hic iusto a magnam sunt doloremque
            voluptatibus obcaecati aperiam eos, sit nihil commodi vitae eius
            voluptatem perspiciatis corporis eaque!
          </div>
          <div class="tc-blue fs-2 m-5">
            3 ways to invest your hard-earned money
          </div>
          <div class="m-5">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda
            omnis modi adipisci facere, provident ullam placeat officia odit
            porro quod expedita esse distinctio at odio, ad ipsa debitis a
            autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
            saepe labore ipsum possimus iusto reiciendis dolorum! Reiciendis,
            eius nisi ipsa, accusantium enim similique beatae reprehenderit amet
            deserunt in sed et. Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Cumque quo aut pariatur blanditiis, repudiandae
            sequi autem odit deserunt minima. Reprehenderit corporis ex
            assumenda quasi obcaecati voluptate porro perspiciatis libero!
            Voluptatibus? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Facere, velit saepe enim ipsa nemo repellendus, corporis
            accusamus non quae molestiae praesentium! Reprehenderit deserunt eum
            at laudantium quo autem ullam excepturi. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Adipisci, libero sint accusantium hic
            officia veritatis aliquid, sed repellendus dicta atque, veniam cum
            numquam consequatur! Quisquam iusto doloribus aut sint commodi.
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit harum id hic iusto a magnam sunt doloremque
            voluptatibus obcaecati aperiam eos, sit nihil commodi vitae eius
            voluptatem perspiciatis corporis eaque!
          </div>
          <div class="tc-blue fs-2 m-5">The Secret</div>
          <div class="m-5">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda
            omnis modi adipisci facere, provident ullam placeat officia odit
            porro quod expedita esse distinctio at odio, ad ipsa debitis a
            autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
            saepe labore ipsum possimus iusto reiciendis dolorum! Reiciendis,
            eius nisi ipsa, accusantium enim similique beatae reprehenderit amet
            deserunt in sed et. Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Cumque quo aut pariatur blanditiis, repudiandae
            sequi autem odit deserunt minima. Reprehenderit corporis ex
            assumenda quasi obcaecati voluptate porro perspiciatis libero!
            Voluptatibus? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Facere, velit saepe enim ipsa nemo repellendus, corporis
            accusamus non quae molestiae praesentium! Reprehenderit deserunt eum
            at laudantium quo autem ullam excepturi. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Adipisci, libero sint accusantium hic
            officia veritatis aliquid, sed repellendus dicta atque, veniam cum
            numquam consequatur! Quisquam iusto doloribus aut sint commodi.
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit harum id hic iusto a magnam sunt doloremque
            voluptatibus obcaecati aperiam eos, sit nihil commodi vitae eius
            voluptatem perspiciatis corporis eaque!
          </div>
          <div class="tc-blue fs-2 m-5">The Catch</div>
          <div class="m-5">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda
            omnis modi adipisci facere, provident ullam placeat officia odit
            porro quod expedita esse distinctio at odio, ad ipsa debitis a
            autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
            saepe labore ipsum possimus iusto reiciendis dolorum! Reiciendis,
            eius nisi ipsa, accusantium enim similique beatae reprehenderit amet
            deserunt in sed et. Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Cumque quo aut pariatur blanditiis, repudiandae
            sequi autem odit deserunt minima. Reprehenderit corporis ex
            assumenda quasi obcaecati voluptate porro perspiciatis libero!
            Voluptatibus? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Facere, velit saepe enim ipsa nemo repellendus, corporis
            accusamus non quae molestiae praesentium! Reprehenderit deserunt eum
            at laudantium quo autem ullam excepturi. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Adipisci, libero sint accusantium hic
            officia veritatis aliquid, sed repellendus dicta atque, veniam cum
            numquam consequatur! Quisquam iusto doloribus aut sint commodi.
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit harum id hic iusto a magnam sunt doloremque
            voluptatibus obcaecati aperiam eos, sit nihil commodi vitae eius
            voluptatem perspiciatis corporis eaque!
          </div>
          <div class="tc-blue fs-2 m-5">Conclusion</div>
          <div class="m-5">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda
            omnis modi adipisci facere, provident ullam placeat officia odit
            porro quod expedita esse distinctio at odio, ad ipsa debitis a
            autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
            saepe labore ipsum possimus iusto reiciendis dolorum! Reiciendis,
            eius nisi ipsa, accusantium enim similique beatae reprehenderit amet
            deserunt in sed et. Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Cumque quo aut pariatur blanditiis, repudiandae
            sequi autem odit deserunt minima. Reprehenderit corporis ex
            assumenda quasi obcaecati voluptate porro perspiciatis libero!
            Voluptatibus? Lorem ipsum, dolor sit amet consectetur adipisicing
            elit. Facere, velit saepe enim ipsa nemo repellendus, corporis
            accusamus non quae molestiae praesentium! Reprehenderit deserunt eum
            at laudantium quo autem ullam excepturi. Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Adipisci, libero sint accusantium hic
            officia veritatis aliquid, sed repellendus dicta atque, veniam cum
            numquam consequatur! Quisquam iusto doloribus aut sint commodi.
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit harum id hic iusto a magnam sunt doloremque
            voluptatibus obcaecati aperiam eos, sit nihil commodi vitae eius
            voluptatem perspiciatis corporis eaque!
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
