import React, { useState } from "react";
import axios from "axios";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle, faFacebook, faApple } from '@fortawesome/free-brands-svg-icons';
import "./LoginStyles.css";

const Verify = () => {
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const receivedData = location.state?.data;
  const [email, setName] = useState(receivedData);
  const [rememberMe, setRememberMe] = useState(false);
  console.log("iiii", email);
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Handle form submission logic here
    const otp = e.target.elements.otp.value;
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
      };
      const response = await axios.post(
        `${baseUrl}/verify`,
        {},
        {
          headers: headers,
          params: {
            email: email,
            otp: otp,
          },
        }
      );
      console.log("Response:", response.data);
      history("/login");
      toast.success("Verification Successful, PLease Login !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Handle successful response here
    } catch (error) {
      toast.error("Invalid OTP, Please try again !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
      // Handle error here
    }
  };

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <p style={{ marginBottom: "10px", fontWeight: "600" }}>Enter OTP</p>
          <div
            className="input-box"
            style={{ marginTop: "0rem", marginBottom: "1rem" }}
          >
            <input id="otp" type="text" placeholder="XXXXXX" required />
            <i className="bx bxs-user"></i>
          </div>
        </div>

        <button type="submit" className="btn">
          Verify
        </button>

        <div className="register-link">
          <p>
            Dont have an account?{" "}
            <a href="/signUp" style={{ color: "rgba(0, 0, 0, 0.7)" }}>
              Sign Up
            </a>
          </p>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Verify;
