import { useState } from "react";

import "./Landing.css";
import Lottie from "lottie-react";
import educationAnimation from "../../assets/animations/education.json";
import secureAnimation from "../../assets/animations/secure.json";
import steps from "../../assets/5 Steps.png";
import Chart from "../../assets/chart.svg";
import { Collapse, Modal, Button } from "antd";
import { Layout } from "antd";
import BookACall from "./BookACall";
const { Header, Content, Footer } = Layout;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const items = [
  {
    key: "1",
    label: " What constituents Cost of Living?",
    children: (
      <p>
        {" "}
        The affordable cost of living in India can often mean having a good
        quality of life. For the most, these typically constitute fixed costs
        towards food item, rentals, education expenses, transportation, etc.
        Needless to mention, financial freedom can significantly be achieved by
        ensuring fixed income to cover the regular fixed expenses
      </p>
    ),
  },
  {
    key: "2",
    label: " How to earn Regular Income (RBI)?",
    children: (
      <p>
        Typically regular income is earned through interest from the fixed
        income instruments. Traditionally, interest income is earned from the
        bank deposits however, there is an increasing trend in favour of
        bonds/other fixed income instruments considering higher interest
        rates/yield on them than bank deposits.
      </p>
    ),
  },
  {
    key: "3",
    label: " What is Term Investment Plan?",
    children: (
      <p>
        {" "}
        Typically different interest rates are offered across tenors. Mostly,
        higher rates are offered for longer maturities. This is also referred as
        ‘Term Premium’. Sometimes, this positive interest differential is as
        high as 3-4%. Therefore, it is critical to consider investment in long
        term bonds in order to maximize interest income.
      </p>
    ),
  },
  {
    key: "4",
    label: "What are different types of investment risks?",
    children: (
      <p>
        While business risk is considered as the most significant across all
        instruments, there are additional risks associated with debt
        instruments. Some of the major risks are default risk, liquidity risk,
        market risk, interest rate risk, reinvestment risk, etc.
      </p>
    ),
  },
  {
    key: "5",
    label: "What is Risk Adjusted Return?",
    children: (
      <p>
        Risk-adjusted return is a critical element to successful long-term
        investing, and one often overlooked by newer investors. Investors may
        make the mistake of focusing on gross return in its most basic form,
        without considering the risks they’re exposed to in order to achieve
        those returns. While no investment is 100% risk-free, Central government
        securities are often used as the proxy for risk-adjusted comparisons.
        Higher risk adjusted returns typically considered desirable and healthy
      </p>
    ),
  },
];
function Landing() {
  const [showTime, setShowTime] = useState(false);
  const [style, setStyle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const timeArray = [
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
  ];

  return (
    <>
      <Modal
        title="Connect with Us!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Book Appointment
          </Button>,
        ]}
      >
        <BookACall />
      </Modal>
      <div class="text-center fw-medium">
        <div class="row ms-5 dynamic-header-wrapper">
          <div class="col-md-7 col-sm-10">
            <div class="dynamic-header fs-2">
              <div class="py-0 text-7xl ">
                Credible Ecosystem providing Fixed Income solution for Retail
              </div>
            </div>
            <div class=" text-start dynamic-header-description-text text-3xl">
              <ul>
                <li>
                  <div>Promoted by distinguished domain experts</div>
                  <div>
                    Governed by a council to guarantee openness and reliable
                    services
                  </div>
                  <div>Strong and committed stakeholders</div>
                </li>
              </ul>
            </div>

            <div class="text-start ">
              <Button type="primary" size="large" onClick={showModal}>
                Book a Free Call
              </Button>{" "}
            </div>
          </div>
          <div class="col-md-5 dynamic-image-parent">
            <Lottie animationData={educationAnimation} loop={true} />
          </div>
        </div>

        <div class=" d-flex justify-content-center flex-column	 h-screen second-card">
          <div>
            <div class="pt-4">
              <div class="text-6xl fw-bold ">
                Comprehensive Risk Assessment Framework
              </div>
              <div className="text-xl">
                Minimising risk by maximising outcome
              </div>
            </div>
            <div class="row mt-3 craf-image">
              <div class="col-md-6 col-sm-12 text-start risk-assesment-framework overflow-auto">
                <div class="verical-center">
                  <p class="fw-bold text-5xl our-solution m-6">Our Solution</p>
                  <p class="ps-5 our-solution-body text-xl">
                    It is critical to assess risk comprehensively for
                    considering investment in the debt instrument. Under the
                    Comprehensive Risk Assessment framework, various high impact
                    factors are analysed through a detailed framework named as
                    TURIYA to provide an indicator about the level of risk
                    associated with the instrument.
                  </p>
                </div>
              </div>
              <div class="col-md-5 our-solution-container">
                <div class="our-solution-image">
                  <Lottie animationData={secureAnimation} loop={true} />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div class="my-5 d-flex justify-content-center flex-column h-screen">
          <div class="row m-3">
            <div class="col-md-6 col-sm-12 text-start verical-center">
              <div class="fw-bold text-md-start text-sm-center credible-eco-header text-6xl">
                Credible <span class="blue-text"> Ecosystem</span>
              </div>
              <hr class="w-75 my-2" />
              <div class="credible-eco-body text-xl">
                Investment in debt instrument is governed by various factors
                such as credit rating, quality of security, restrictive
                covenants and quality of documentation which are monitored by
                the designated market participants such as rating agencies,
                trustees, escrow banks, bankers, etc. It is therefore critical
                to have a credible ecosystem to ensure sustainability of quality
                of debt instrument over the term of the instrument.
              </div>
              <div>
                <button class="btn btn-primary btn-lg call-button fs-5 tbc-blue mt-5 more-button">
                  More
                </button>
              </div>
            </div>
            <div class="col-md-5 col-sm-12 icon-cards">
              <div class="row my-4 icon-cards">
                <div class="col-4 border-end">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-android2 craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">
                    Comprehensive Risk Assessment Framework
                  </div>
                </div>
                <div class="col-4 border-end">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-apple craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">Knowledge HUB</div>
                </div>
                <div class="col-4">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-bag-check-fill craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">Domain Experts</div>
                </div>
              </div>
              <div class="row icon-cards">
                <div class="col-4 border-end">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-bank craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">High Governance Standards</div>
                </div>
                <div class="col-4 border-end">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-briefcase-fill craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">Simplified Solutions</div>
                </div>
                <div class="col-4">
                  <div class="m-auto craf-card rounded shadow border-end">
                    <i class="bi bi-browser-safari craf-icon blue-text"></i>
                  </div>

                  <div class="fw-bold mt-2">Advanced technology</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="m-md-5 phone-faq-container h-screen d-flex justify-content-center flex-column">
          <div>
            <div class="text-6xl fw-bold">
              <span class="header-color ">FAQs</span>
            </div>
            <div class="header-description text-xl">
              Have a few questions? We are more than happy to provide
              <br />
              you with answers
            </div>
            <div class="row mt-3 text-start margin">
              <div class="col-md-6 verical-center text-start faq-phone">
                <Collapse items={items} defaultActiveKey={["1"]} />;
                <div class="text-center my-5">
                  <button class="px-5 py-2 fw-bold rounded">View More</button>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 text-start ps-5 faq-phone">
                <div class="fs-2 fw-bold">
                  <div>Have more questions?</div>
                  <div class="blue-text">Let's talk</div>
                </div>
                <div class="my-3 faq-bottom">
                  Our team of highly trained professionals will clear all your
                  doubts with utmost transparency and clarity.
                </div>
                <div class="my-3 faq-bottom">
                  <button
                    class="w-full btn btn-success fw-medium btn-lg me-3 my-3"
                    data-bs-toggle="modal"
                    data-bs-target="#signUpModal"
                  >
                    Join us<i class="ms-2 bi bi-arrow-right"></i>
                  </button>

                  <div class="my-3 faq-bottom">
                    <button
                      class="w-full btn btn-primary btn-lg call-button fs-5 tbc-blue"
                      onClick={showModal}
                    >
                      Book a Free Call
                    </button>
                  </div>
                </div>

                <div class="my-3">
                  We are available on all days between 10 AM to 7PM
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5 h-screen d-flex justify-content-center flex-column">
          <div>
            <div class="text-6xl fw-bold">
              Get better returns than <span class="header-color">Debt MF</span>,
              <br />
              at Lower risk than <span class="header-color">Equities</span>
            </div>
            <div class="mt-5">
              <img
                src={Chart}
                height="700px"
                width="700px"
                alt=""
                className="chat-img"
              />
            </div>
          </div>
        </div>

        <div class="my-5 h-screen d-flex justify-content-center flex-column">
          <div class="my-5 text-center">
            <div class="text-6xl fw-bold">
              How to
              <span class="text-6xl blue-text fw-bold"> Invest</span>
              <div class="text-6xl fw-bold">
                Our
                <span class="text-6xl blue-text fw-bold">5-Step Method</span>
              </div>
              <div class="text-xl text-secondary">
                Investing in Bonds in 5 simple steps...
              </div>
            </div>
            <div class="my-5">
              <div class="example-input-wrapper">
                <img class="h-50 w-100" src={steps} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="hero">
        <div className="text">
          <h1>Credible Ecosystem providing Fixed Income solution for Retail</h1>
          <p>
            Restricted to high quality instruments. Comprehensive fixed income
            Solutions. Ease of execution.
          </p>
          <button className="call" onClick={onOpen}>
            Book a Free Call
          </button>
        </div>
        <div className="img">
          <img src={HeroImg} alt="image" className="heroImg" />
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"80%"} h={"80vh"}>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            py={"2.5rem"}
          >
            <div className="modal">
              <div className="fir">
                <p className="talk">Talk to an advisor</p>
                <p className="min">
                  <FaClock />
                  30 mins
                </p>
                <p className="desc">
                  An average call usually lasts 30 minutes and you can get all
                  your queries addressed during this time
                </p>
              </div>
              <div className="sec">
                {showTime ? (
                  <div className="t">
                    <input type="date" name="" className="date" />
                    <div className="timing">
                      {timeArray.map((time, i) => (
                        <div className="time">{time}</div>
                      ))}
                      <input
                        type="button"
                        className="bookCall call1"
                        value="Confirm"
                        onClick={() => setShowTime(!showTime)}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="talk">
                      Please let us know if your query is regarding Lorem Ipsum,
                      Lorem Ipsume or both? *
                    </p>
                    <div className="radio">
                      <input type="radio" name="lorem" className="rad" />
                      <span>Lorem Ipsum</span> <br />
                      <input type="radio" name="lorem" className="rad" />
                      <span>Lorem Ipsum</span> <br />
                      <input type="radio" name="lorem" className="rad" />
                      <span>Lorem Ipsum</span> <br />
                    </div>
                    <p className="talk">Please let us know your query *</p>
                    <textarea></textarea>

                    <input
                      type="button"
                      className="bookCall call1"
                      value="Select Time"
                      onClick={() => setShowTime(!showTime)}
                    />
                  </>
                )}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <FAQ open={onOpen} /> */}
    </>
  );
}

export default Landing;
