import { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FacebookOutlined,
  GoogleOutlined,
  AppleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle, faFacebook, faApple } from '@fortawesome/free-brands-svg-icons';
import "./LoginStyles.css";

const Login = () => {
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrlRazorpay = process.env.REACT_APP_RAZORPAY_BASE_URL;

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  const handleSubmitSignup = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Handle form submission logic here
    console.log(e);
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;
    const email = e.target.elements.email.value;
    const mobNo = e.target.elements.phoneNo.value;

    console.log("Username:", username);
    console.log("Password:", password);
    console.log("Email:", email);
    let formData = {
      name: username,
      password: password,
      email: email,
      mobNo: mobNo,
    };
    try {
      const response = await axios.post(`${baseUrl}/signup`, formData);
      console.log("Response:", response.data);
      toast.success("Registration Successful !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Handle successful response here

      history("/verify", { state: { data: email } });
    } catch (error) {
      toast.error("Something went Wrong, Please try again !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
      // Handle error here
    }
  };
  const loginRazorPay = async () => {
    let formData = { email: "admin@svirtz.com", password: "admin@" };
    try {
      const response = await axios.post(
        `${baseUrlRazorpay}/api/auth/signin`,
        formData
      );
      console.log("Response:", response.data);

      localStorage.setItem("razorpayAuthToken", response.data.accessToken);
      // localStorage.setItem("refresh_token", response.data.refresh);

      // Handle successful response here
    } catch (error) {
      toast.error("Something went Wrong, Please try again !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
      // Handle error here
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Handle form submission logic here
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;
    console.log("email:", username);
    console.log("Password:", password);
    let formData = { email: username, password: password };
    try {
      const response = await axios.post(`${baseUrl}/login`, formData);
      console.log("Response:", response.data);

      localStorage.setItem("authToken", response.data.jwt);
      localStorage.setItem("refresh_token", response.data.refresh);
      loginRazorPay();
      history("/d/dash");
      // Handle successful response here
    } catch (error) {
      toast.error("Something went Wrong, Please try again !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
      // Handle error here
    }
  };

  return (
    <div style={{ backgroundColor: "#4b5d7a" }}>
      <Button
        type="primary"
        shape="round"
        title="Back "
        className="my-5 mx-3"
        icon={<LeftOutlined />}
        onClick={() => history("/")}
      >
        Go Back
      </Button>
      <div className="login-page-container ">
        <div className="wrapper">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>
                Username
              </p>
              <div
                className="input-box"
                style={{ marginTop: "0rem", marginBottom: "1rem" }}
              >
                <input
                  id="username"
                  type="text"
                  placeholder="deniel123@gmail.com"
                  required
                />
                <i className="bx bxs-user"></i>
              </div>
            </div>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>
                Password
              </p>
              <div className="input-box" style={{ marginTop: "0rem" }}>
                <input
                  id="password"
                  type="password"
                  placeholder={"• • • • • • •"}
                  required
                />
                <i className="bx bxs-lock-alt"></i>
              </div>
            </div>
            <div className="remember-forgot">
              <label className={rememberMe ? "checkbox checked" : "checkbox"}>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                Remember Me
              </label>
              <a href="#">Forgot Password</a>
            </div>
            <button type="submit" className="btn">
              Login
            </button>
            <div className="register-link"></div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div className="icon-container">
                <FacebookOutlined style={{ fontSize: 30 }} />{" "}
                <GoogleOutlined style={{ fontSize: 30 }} />
                <AppleOutlined style={{ fontSize: 30 }} />
              </div>{" "}
            </div>
            <div className="register-link">
              <p>
                Dont have an account? <Link to="/signUp"> Sign Up</Link>
              </p>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>{" "}
    </div>
  );
};

export default Login;
