// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-100%);
    }
  }
  
  img {
    display: inline-block;
    height: 80px;
    width: 200px;
    margin-right: 20px;
  }
  .slider {
    background: #fff;
    overflow: hidden;
    position: relative;
    display: flex;
  }
  .slider:before {
    left: 0;
    top: 0;
  }
  .slider:after,
  .slider:before {
    background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
    content: "";
    position: absolute;
    width: 100%;
  }
  .slider .slide-track {
    animation: scroll 20s linear infinite;
    display: flex;
    width: 100vw;
  }
  .cards{
    min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/testimonials.css"],"names":[],"mappings":"AAAA;IACI;MACE,wBAAwB;IAC1B;;IAEA;MACE,4BAA4B;IAC9B;EACF;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;EACf;EACA;IACE,OAAO;IACP,MAAM;EACR;EACA;;IAEE,gEAAgE;IAChE,WAAW;IACX,kBAAkB;IAClB,WAAW;EACb;EACA;IAEE,qCAAqC;IACrC,aAAa;IACb,YAAY;EACd;EACA;IACE,gBAAgB;AACpB","sourcesContent":["@keyframes scroll {\n    0% {\n      transform: translateX(0);\n    }\n  \n    100% {\n      transform: translateX(-100%);\n    }\n  }\n  \n  img {\n    display: inline-block;\n    height: 80px;\n    width: 200px;\n    margin-right: 20px;\n  }\n  .slider {\n    background: #fff;\n    overflow: hidden;\n    position: relative;\n    display: flex;\n  }\n  .slider:before {\n    left: 0;\n    top: 0;\n  }\n  .slider:after,\n  .slider:before {\n    background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));\n    content: \"\";\n    position: absolute;\n    width: 100%;\n  }\n  .slider .slide-track {\n    -webkit-animation: scroll 40s linear infinite;\n    animation: scroll 20s linear infinite;\n    display: flex;\n    width: 100vw;\n  }\n  .cards{\n    min-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
