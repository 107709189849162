import { useLocation, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Col,
  Icon,
  Input,
  List,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import App from "../App";
import axios from "axios";
const { Title } = Typography;

const Financial = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [isin_data, setIsinData] = useState([]);

  const location = useLocation();
  const receivedData = location.state?.data;
  console.log("receivedData", receivedData);
  const [name, setName] = useState(
    receivedData && receivedData.name
      ? receivedData.name.replace("Limited", "Ltd")
      : ""
  );

  const fetchIsinData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        // You can add more headers if needed
      };

      const response = await axios.get(`${baseUrl}/bse/name/${name}`, {
        headers,
      });
      if (response && response.data) {
        console.log("--------------------");
        console.log(response.data);
        const dataArray = Object.entries(response.data[0].data).map(
          ([key, value]) => ({ key, value })
        );
        setIsinData(dataArray);

        console.log(dataArray);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchIsinData();
  }, []);
  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "isin",
    },
    {
      title: "",
      dataIndex: "value",
      key: "securitydescription",
    },
  ];
  return (
    <div>
      <div style={{ display: "flex" }}>

        <Title level={2}>Financial Details of {name}</Title>
      </div>

      <Row>
        <Table dataSource={isin_data} columns={columns} pagination={false} />
      </Row>
    </div>
  );
};
export default Financial;
