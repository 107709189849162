import { useState } from "react";
import "./BookACall.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const BookACall = () => {
  const [selectTime, setSelectTime] = useState(false);

  const submit = () => {
    console.log("Submitted");
  };
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  return (
    <div>
      <div class="row">
        {!selectTime && (
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="fs-3 fw-bold my-3">Talk to an advisor</div>
              <div>
                <i class="bi bi-clock-fill fs-5"></i>
                <span class="fs-5"> 30 Min</span>
                <div class="text-secondary fs-5 my-3">
                  An average call usually lasts 30 minutes and you can get all
                  your queries addressed during this time
                </div>
              </div>

              <DatePicker
                size="large"
                style={{ width: 100 + "%" }}
                placeholder="Select Date and Time"
                disabledDate={disabledDate}
                showTime={{ format: "HH:mm a", minuteStep: 30 }}
                onOk={onOk}
                onChange={(value, dateString) => {
                  console.log("Selected Time: ", value);
                  console.log("Formatted Selected Time: ", dateString);
                }}
              />
            </div>
            <div class="col-md-6">
              <div class="fs-4 fw-medium my-2 tc-blue">
                Please let us know your query *
              </div>
              <div class="form-floating my-4">
                <textarea
                  class="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: 100 + "px" }}
                ></textarea>
                <label for="floatingTextarea2"></label>
              </div>
              {/* <div className="d-flex flex-wrap gap-5 my-5">
                {times.map((time, index) => (
                  <div key={index} className="w-auto tc-blue fs-5">
                    <div className="time-card p-3">{time}</div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookACall;
