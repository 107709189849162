import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const BondsTradedInCapitalMarket = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/nse-trading-vol`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response data to the console
        setAnnouncementData(data);
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, []);

  return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>
          Bonds Traded In Capital Market [Trading Volume]{" "}
          <a
            href="https://www.nseindia.com/market-data/bonds-traded-in-capital-market"
            target="_blank"
          >
            LINK
          </a>{" "}
        </h1>
      </div>
      <table className="announcement-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Coupon Rate</th>
            <th>Credit Rating</th>
            <th>face_Value </th>
            <th>LTP</th>
            <th>Maturity Date</th>
            <th>Percentage Change</th>
            <th>Series</th>
            <th>Bond Type</th>
            <th>Value in Crores</th>
            <th>Volume Shares</th>
          </tr>
        </thead>
        <tbody>
          {announcementData.map((announcement, index) => (
            <tr key={index}>
              <td>{announcement.symbol}</td>
              <td>{announcement.coupon_Rate}</td>
              <td>{announcement.credit_Rating}</td>
              <td>{announcement.face_Value}</td>
              <td>{announcement.ltp}</td>
              <td>{announcement.maturity_Date}</td>{" "}
              <td>{announcement.percentage_Change}</td>
              <td>{announcement.series}</td>
              <td>{announcement.bond_Type}</td>
              <td>{announcement.value_Crores}</td>
              <td>{announcement.volume_Shares}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BondsTradedInCapitalMarket;
