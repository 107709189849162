// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.debt-info {
    font-family: Arial, sans-serif;
    margin: 20px;
   
    margin: 0 auto;
}

.debt-info h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.grid-item {
    background-color: #f4f4f4;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-info {
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 10px;
    border: 1px solid #ddd;
}

.field {
    font-weight: bold;
    background-color: #f4f4f4;
    width: 30%;
    max-width: 30%;
    min-width: 30%;
}

.value {
    width: 70%;
    max-width: 70%;
    min-width: 70%;
    word-break: break-word;
}

/* Optional: CSS Grid classes for col-4 and col-8 */
.col-4 {
    width: 30%;
}

.col-8 {
    width: 70%;
}
`, "",{"version":3,"sources":["webpack://./src/components/nse/NSEDebtPdfData.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;;IAEZ,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,UAAU;IACV,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,cAAc;IACd,sBAAsB;AAC1B;;AAEA,mDAAmD;AACnD;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".debt-info {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n   \n    margin: 0 auto;\n}\n\n.debt-info h1 {\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: 2em;\n}\n\n.grid-container {\n    display: grid;\n    grid-template-columns: 1fr;\n    gap: 20px;\n}\n\n.grid-item {\n    background-color: #f4f4f4;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.page-info {\n    margin-bottom: 10px;\n    font-weight: bold;\n    color: #333;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n}\n\ntd {\n    padding: 10px;\n    border: 1px solid #ddd;\n}\n\n.field {\n    font-weight: bold;\n    background-color: #f4f4f4;\n    width: 30%;\n    max-width: 30%;\n    min-width: 30%;\n}\n\n.value {\n    width: 70%;\n    max-width: 70%;\n    min-width: 70%;\n    word-break: break-word;\n}\n\n/* Optional: CSS Grid classes for col-4 and col-8 */\n.col-4 {\n    width: 30%;\n}\n\n.col-8 {\n    width: 70%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
