import BlogCard from "./BlogCard";
import BlogDashImg from "../../assets/blog_dash_img.svg";
import { useNavigate } from "react-router-dom";
const BlogDashboard = () => {
  const navigate = useNavigate();
  return (
    <div class=" text-center my-5">
      <div class="tc-blue fs-1 fw-bold">@SimplyBonds Blog Wall</div>

      <div class="row my-5">
        <div class="col-md-6 align-self-center">
          <div class="fs-1">
            Here What the
            <span class="fs-1 tc-blue fw-bold">Experts</span>
            have to say when it come to
            <span class="fs-1 tc-blue fw-bold">Finance.</span>
          </div>
          <div class="text-secondary fs-3">
            Select a topic and delve into the genre of
            <span class="fs-3 tc-blue fw-bold">Bonds</span>
          </div>
        </div>
        <div class="col-md-6">
          <img class="w-100 h-100" src={BlogDashImg} alt="" />
        </div>
      </div>
      <div class="m-md-5">
        <input
          class="form-control form-control-lg"
          type="text"
          placeholder="Search by keyword"
        />
      </div>
      <div>
        <div class="d-flex blog-card-list">
          <BlogCard></BlogCard>
          <BlogCard></BlogCard>
          <BlogCard></BlogCard> <BlogCard></BlogCard>
          <BlogCard></BlogCard>
          <BlogCard></BlogCard> <BlogCard></BlogCard>
          <BlogCard></BlogCard>
          <BlogCard></BlogCard>
        </div>
      </div>
    </div>
  );
};

export default BlogDashboard;
