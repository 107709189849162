import { useState } from "react";
import {
  Steps,
  Form,
  Input,
  DatePicker,
  Button,
  Upload,
  Radio,
  message,
} from "antd";
import { useEffect } from "react";
import { Row, Col, Typography } from "antd";
const { Title, Text } = Typography;
const { Step } = Steps;

const KycSteps = () => {
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isDematAccountAdded, setIsDematAccountAdded] = useState(false);
  const [isBankAccountAdded, setIsBankAccountAdded] = useState(false);
  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [current, setCurrent] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [aadhaarNumber, setAadhaarNumber] = useState("");

  const [panForm] = Form.useForm(); //
  const [aadhaarForm] = Form.useForm(); // Use Ant Design's Form hook
  const [demataccountForm] = Form.useForm();
  const [bankaccountForm] = Form.useForm();

  const [otp, setOtp] = useState("");
  const [recognizedText, setRecognizedText] = useState("");
  const [dematAccounts, setDematAccounts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const maxDateValidator = (dateString) => {
    const selectedDate = new Date(dateString);
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    return selectedDate <= todayDate;
  };

  const pre = () => {
    setCurrent((prev) => prev - 1);
    setIsCaptchaValid(false);
    setIsDematAccountAdded(false);
    setIsBankAccountAdded(false);
    setIsVerifyOtp(false);
  };
  const handleFetchFromNSDL = () => {
    // Implement your fetch logic here
  };

  const onFinish = (values) => {
    console.log("Form values:", values);
  };
  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const done = () => {
    console.log("done");
  };

  const captchaValidate = () => {
    setIsCaptchaValid(true);
  };

  const otpChange = (e) => {
    setOtp(e.target.value);
  };

  const verifyOtp = () => {
    setIsVerifyOtp(true);
  };

  const saveDematAccount = () => {
    setIsDematAccountAdded(true);
    console.log(demataccountForm.getFieldsValue().DPID);
    const newAccount = {
      dpId: demataccountForm.getFieldsValue().DPID,
      dpName: demataccountForm.getFieldsValue().DPName,
      clientId: demataccountForm.getFieldsValue().ClientID,
      depository: "SimplyBonds",
    };

    setDematAccounts([...dematAccounts, newAccount]);
  };

  const saveBankAccount = () => {
    setIsBankAccountAdded(true);

    const newBankAccount = {
      bankName: bankaccountForm.getFieldsValue().bankName,
      ifscCode: bankaccountForm.getFieldsValue().ifscCode,
      accountNumber: bankaccountForm.getFieldsValue().accountNumber,
    };

    setBankAccounts([...bankAccounts, newBankAccount]);
  };

  const handleUploadPan = async (event) => {
    message.success("Uploading PAN Card");
  };

  const handleUploadAadhar = async (event) => {
    const aadharFile = event.target.files[0];
  };
  const addAnotherAccountNumber = () => {
    setIsBankAccountAdded(false);
  };
  const verifyPan = (pan) => {
    next();
  };
  const captchaVaildate = () => {
    setIsCaptchaValid(true);
    setAadhaarNumber(aadhaarForm.getFieldValue("aadhaarNumber"));
  };
  const addAnotherDematAccount = () => {
    setIsDematAccountAdded(false);
  };

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="fs-3 tc-blue my-3">Identity Verification</div>
          <Steps current={current}>
            <Step title="PAN" />
            <Step title="AADHAAR" />
            <Step title="DEMAT" />
            <Step title="BANK" />
            <Step title="OTHER" />
            <Step title="ESIGN" />
          </Steps>
          <div className="steps-content pt-1">
            <div className="row">
              {/* <div className="col-md-4 p-4">
                <div className=" my-3">
                  <div className="fs-5 fw-bold">SimplyBonds helps</div>
                  <Carousel
                    arrows
                    prevArrow={<LeftOutlined />}
                    nextArrow={<RightOutlined />}
                  >
                    <div>Text One</div>
                    <div>Text Two</div>
                    <div>Text Three</div>
                  </Carousel>
                  <div>
                    <img
                      src="https://client.bondsindia.com/static/media/mascotform.f1f4af73.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              <div className=" card p-4">
                {current === 0 && (
                  <Form form={panForm} layout="vertical">
                    <div className=" text-start">
                      <div className="fs-3 mt-3 fw-bold">
                        Let’s start by filling your PAN details
                      </div>
                      <Upload customRequest={handleUploadPan}>
                        <Button>Upload PAN</Button>
                      </Upload>
                      <div className="my-5 w-50 text-center">
                        <div className="fs-5">OR</div>
                      </div>
                      <Form.Item
                        label="PAN"
                        name="panNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter valid PAN Number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Date of Birth"
                        name="dateofBirth"
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker format="DD-MM-YYYY" />
                      </Form.Item>
                      <div className="my-3 fs-6 mb-3">
                        Please mention your Date of Birth as mentioned in your
                        Pan Card
                      </div>
                    </div>
                  </Form>
                )}
                {current === 1 && !isCaptchaValid && (
                  <Form form={aadhaarForm} layout="vertical">
                    <div className=" text-start">
                      <div className="fs-3 mt-3 fw-bold">
                        Let’s start by filling your AADHAAR details
                      </div>
                      <Upload customRequest={handleUploadAadhar}>
                        <Button>Upload AADHAAR</Button>
                      </Upload>
                      <Form.Item
                        label="AADHAAR"
                        name="aadhaarNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter valid AADHAAR Number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <img
                            className="mt-3"
                            src="https://www.pandasecurity.com/en/mediacenter/src/uploads/2014/09/avoid-captcha.jpg"
                            width="200"
                            alt=""
                          />
                        </div>
                      </div>
                      <Form.Item label="CAPTCHA">
                        <Input />
                      </Form.Item>
                    </div>
                  </Form>
                )}
                {current === 1 && isCaptchaValid && !isVerifyOtp && (
                  <div className=" text-start">
                    <div className="fs-3 mt-3 fw-bold">
                      Let's quickly verify your AADHAAR details
                    </div>
                    <Form.Item label="AADHAAR">
                      <Input value="AadhaarNumber" disabled />
                    </Form.Item>
                    <div className="text-start mb-3">
                      <div className="mt-3">OTP Verification</div>
                      <div className="mb-3 fs-6">
                        Please enter the OTP sent to your registered UID Mobile
                        number
                      </div>
                      {/* Replace this with actual OTP input component */}
                      <Input placeholder="Enter OTP" />
                    </div>
                  </div>
                )}
                {current === 2 && !isDematAccountAdded && (
                  <div className=" text-start">
                    <Title level={3} className="mt-3 fw-bold">
                      Let's verify your DEMAT Account
                    </Title>
                    <Text className="my-2 fs-6">
                      In the interest of flexibility of trade, we allow users to
                      add up to 5 Demat A/cs held with any Depository
                      Participant (DP)
                    </Text>
                    <Title level={5} className="fw-bold mt-3">
                      Already have a Demat account?
                    </Title>
                    <Button
                      type="primary"
                      danger
                      size="large"
                      className="px-3"
                      onClick={handleFetchFromNSDL}
                    >
                      FETCH IT FROM NSDL
                    </Button>
                    <Text className="my-2 fs-6">Or</Text>
                    <Title level={5} className="fw-bold">
                      Just Fill in the details
                    </Title>
                    {/* <Row>
                      <Col md={5} sm={24}>
                        <Input
                          type="text"
                          placeholder="Search DP Name/DP ID"
                          required
                        />
                        <label className="fs-6">Search DP Name/DP ID</label>
                      </Col>
                    </Row> */}

                    <Form
                      form={demataccountForm}
                      onFinish={onFinish}
                      layout="vertical"
                    >
                      <Row>
                        <Col md={6} sm={24}>
                          <Form.Item
                            name="DPID"
                            rules={[
                              {
                                required: true,
                                message: "Please enter valid DP ID",
                              },
                              {
                                pattern: /^[0-9]{10}$/,
                                message: "Enter valid 10 digit DP ID",
                              },
                            ]}
                          >
                            <Input placeholder="DP ID" />
                          </Form.Item>
                        </Col>

                        <Col md={6} sm={24}>
                          <Form.Item
                            name="DPName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter valid DP Name",
                              },
                            ]}
                          >
                            <Input placeholder="DP Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={24}>
                          <Form.Item
                            name="ClientID"
                            rules={[
                              {
                                required: true,
                                message: "Please enter valid Client ID",
                              },
                              {
                                pattern: /^[A-Z]{4}[0-9]{8}$/,
                                message:
                                  "Enter valid Client ID (e.g., ABCD12345678)",
                              },
                            ]}
                          >
                            <Input placeholder="Client ID" />
                          </Form.Item>
                        </Col>
                        <Col md={6} sm={24}>
                          <Form.Item
                            name="ConfirmClientID"
                            dependencies={["ClientID"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your Client ID",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("ClientID") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Client IDs do not match")
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Confirm Client ID" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form>
                  </div>
                )}
                {current === 2 && isDematAccountAdded && (
                  <div className=" text-start mx-3">
                    <div className="mt-3 fs-3 fw-bold">
                      Let’s verify your DEMAT Account
                    </div>
                    <div className="my-2 fs-6">
                      In the interest of flexibility of trade, we allow users to
                      add up to 5 Demat A/cs held with any Depository
                      Participant (DP)
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-6">
                        <div className="fs-4 fw-bold">Depository Details</div>
                      </div>
                      <div className="col-md-6 text-end">
                        <Button
                          onClick={addAnotherDematAccount}
                          className="primary"
                        >
                          + Add Another Account
                        </Button>
                      </div>
                    </div>
                    <div className="my-4">
                      <table className="table border border-3 rounded-3">
                        <thead>
                          <tr>
                            <th scope="col">Depository</th>
                            <th scope="col">DP Name</th>
                            <th scope="col">DP ID</th>
                            <th scope="col">Client ID</th>
                            <th scope="col">Default</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dematAccounts.map((depositoryDetails, index) => (
                            <tr key={index}>
                              <td>{depositoryDetails.depository}</td>
                              <td>{depositoryDetails.dpName}</td>
                              <td>{depositoryDetails.dpId}</td>
                              <td>{depositoryDetails.clientId}</td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id={`flexRadioDefault${index}`}
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="fs-6 mt-3 mb-3">
                      Note: If you wish to add more than 5 depository accounts
                      from NSDL or CDSL, we will randomly pick one from your
                      selection.
                    </div>
                  </div>
                )}

                {current === 3 && !isBankAccountAdded && (
                  <div className=" text-start">
                    <div className="fs-3 fw-bold mt-3">
                      Let’s verify your BANK Account
                    </div>
                    <div className="my-3 fs-6">
                      In the interest of flexibility of trade, we allow users to
                      add up to 5 Demat A/cs held with any Depository
                      Participant (DP)
                    </div>
                    <Form form={bankaccountForm}>
                      <div className="row">
                        <div className="col-md-5 col-sm-12">
                          <div className="">
                            <Form.Item
                              name="ifscCode"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a valid IFSC Code",
                                },
                              ]}
                            >
                              <Input
                                className="w-100"
                                required
                                placeholder="IFSC"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-12 text-center">
                          <div>OR</div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                          <div className="">
                            <Input
                              className="w-100"
                              required
                              placeholder="Search IFSC"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col sm-12 col-md-6">
                          <div className=" my-3">
                            <Form.Item
                              name="bankName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a valid Bank Name",
                                },
                              ]}
                            >
                              <Input
                                className="w-100"
                                required
                                placeholder="Bank Name"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="my-3">
                        <div>Type</div>
                        <div className="d-flex gap-5">
                          <Form.Item name="accountType">
                            <Radio.Group>
                              <Radio value="Saving">Saving</Radio>
                              <Radio value="Current">Current</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="my-3">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className=" mb-3">
                              <Form.Item
                                name="accountNumber"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter a valid Account Number",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-100"
                                  required
                                  placeholder="Account Number"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className=" mb-3">
                              <Form.Item
                                name="confirmAccountNumber"
                                dependencies={["accountNumber"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please confirm your Account Number",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue("accountNumber") === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                          "The two account numbers do not match!"
                                        )
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  className="w-100"
                                  required
                                  placeholder="Confirm Account Number"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {current === 3 && isBankAccountAdded && (
                  <div className="">
                    <div className=" text-start mx-3">
                      <div className="mt-3 fw-bold fs-3">
                        Let’s verify your Bank Account
                      </div>
                      <div className="my-2 fs-6">
                        In the interest of flexibility of trade, we allow users
                        to add up to 5 Demat A/cs held with any Depository
                        Participant (DP)
                      </div>
                      <div className="row mt-5">
                        <div className="col-md-6">
                          <div className="fs-4 fw-bold">Bank Details</div>
                        </div>
                        <div className="col-md-6 text-end">
                          <Button
                            onClick={addAnotherAccountNumber}
                            className="primary"
                          >
                            + Add Another Account
                          </Button>
                        </div>
                      </div>
                      <div className="my-4">
                        <table className="table border border-3 rounded-3">
                          <thead>
                            <tr>
                              <th scope="col">Bank Name</th>
                              <th scope="col">Bank IFSC</th>
                              <th scope="col">Account Number</th>
                              <th scope="col">Default</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bankAccounts.map((bankDetails, index) => (
                              <tr key={index}>
                                <td>{bankDetails.bankName}</td>
                                <td>{bankDetails.ifscCode}</td>
                                <td>{bankDetails.accountNumber}</td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={`flexRadioDefaultBank${index}`}
                                      checked
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="fs-6 my-3 mb-3">
                        Note: If you wish to add more than 5 depository accounts
                        from NSDL or CDSL, we will randomly pick one from your
                        selection.
                      </div>
                    </div>
                  </div>
                )}

                {current === 4 && (
                  <div className="">
                    <div>KYC Form</div>
                  </div>
                )}

                {current === 5 && (
                  <div className="">
                    <div>Congratulations!</div>
                  </div>
                )}
                <div className=" w-100 text-center">
                  {current === 0 && (
                    <Button
                      type="primary"
                      onClick={() => verifyPan(panForm.getFieldsValue())}
                    >
                      Next
                    </Button>
                  )}

                  {current === 1 && isCaptchaValid && isVerifyOtp && (
                    <Button type="primary" onClick={next}>
                      Next
                    </Button>
                  )}

                  {current === 1 && !isCaptchaValid && (
                    <Button type="primary" onClick={captchaVaildate}>
                      Validate
                    </Button>
                  )}

                  {current === 1 && !isVerifyOtp && isCaptchaValid && (
                    <Button type="primary" onClick={verifyOtp}>
                      Verify OTP
                    </Button>
                  )}

                  {current === 2 && isDematAccountAdded && (
                    <Button type="primary" onClick={next}>
                      Continue
                    </Button>
                  )}

                  {current === 4 && (
                    <Button type="primary" onClick={next}>
                      CLICK HERE TO E-SIGN
                    </Button>
                  )}

                  {current === 2 && !isDematAccountAdded && (
                    <Button type="primary" onClick={saveDematAccount}>
                      Save
                    </Button>
                  )}

                  {current === 3 && isBankAccountAdded && (
                    <Button type="primary" onClick={next}>
                      Continue
                    </Button>
                  )}

                  {current === 3 && !isBankAccountAdded && (
                    <Button type="primary" onClick={saveBankAccount}>
                      Verify & Save
                    </Button>
                  )}

                  {current === 5 && (
                    <Button type="primary" onClick={done}>
                      Done
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycSteps;
