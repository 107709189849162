// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leader-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
}
img {
  width: 80%;
}
.leader-name {
  display: flex;
  align-items: center;
}
::marker {
  color: #387ed1;
}

.blog-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}
.person-avatar {
  font-size: 15rem;
}
ul {
  list-style-type: disc !important
}`, "",{"version":3,"sources":["webpack://./src/components/Leadership/Leadership.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,gBAAgB;AAClB;AACA;EACE;AACF","sourcesContent":[".leader-list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 60px;\n}\nimg {\n  width: 80%;\n}\n.leader-name {\n  display: flex;\n  align-items: center;\n}\n::marker {\n  color: #387ed1;\n}\n\n.blog-card-list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 50px;\n}\n.person-avatar {\n  font-size: 15rem;\n}\nul {\n  list-style-type: disc !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
