// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resident-input-container {
    
    min-width: 250px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 15px;
}
.selected-resident-input {
    background: #fff8ee !important;
    border: 1px solid #e8780a !important;
}`, "",{"version":3,"sources":["webpack://./src/components/kyc/KycKickStart.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,8BAA8B;IAC9B,oCAAoC;AACxC","sourcesContent":[".resident-input-container {\n    \n    min-width: 250px;\n    height: 80px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #e3e3e3;\n    box-sizing: border-box;\n    border-radius: 15px;\n}\n.selected-resident-input {\n    background: #fff8ee !important;\n    border: 1px solid #e8780a !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
