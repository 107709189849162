// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/fonts.css */

@font-face {
    font-family: 'Inter';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Inter';
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,oBAAoB;IACpB,+DAAwF;IACxF,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;EACtB","sourcesContent":["/* src/styles/fonts.css */\n\n@font-face {\n    font-family: 'Inter';\n    src: url('/src/assets/fonts/Inter/Inter-VariableFont_slnt\\,wght.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  body {\n    font-family: 'Inter';\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
