import React from "react";
import "../shared/staticStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import App from "../../App";

const DomainConcepts = () => {
  const navigate = useNavigate();
  return (
    <div className="Start">
      {/* <Sidebar /> */}
      <div className="main-content">
        <div>
          <div className="tabs">
            <button>Visit Website</button>
            <button>Blog</button>
            <button>Contacts</button>
          </div>
          <hr />
          <h4 style={{ fontWeight: 200 }}>
            Home / Knowledge Base / Domain Concepts
          </h4>
          <h1>Domain Concepts</h1>
          <p style={{ marginTop: "1rem" }}>
            Domain Concepts is your gateway to in-depth exploration of
            fundamental principles and theories that underpin our industry.
            Delve into detailed explanations and insightful analyses covering a
            diverse range of topics within our domain. Whether you're seeking
            foundational knowledge or advanced insights, this section offers
            comprehensive resources to satisfy your curiosity and expand your
            understanding of our field.
            <br />
            <br />
            <b>
              We have identified and broken it down into three parts for further
              simplification: Must Know, Great To Know, and Good To Know.
            </b>
          </p>
        </div>

        <div className="small-card-container">
          <div className="small-card">
            <div
              onClick={() => navigate("mustknow")}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p className="small-card-title">Must Know Category</p>
              <p className="small-description">
                Essential insights and foundational knowledge required for
                understanding key concepts within our domain.
              </p>
              <p className="small-article-count">
                6 Articles
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="small-arrow-icon"
                />
              </p>
            </div>
          </div>
          <div className="small-card">
            <Link
              to="/domain/greatoknow"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p className="small-card-title">Great To Know Category</p>
              <p className="small-description">
                Additional information and insights that enhance your
                understanding and provide deeper insights into our industry.
              </p>
              <p className="small-article-count">
                6 Articles
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="small-arrow-icon"
                />
              </p>
            </Link>
          </div>
          <div className="small-card">
            <Link
              to="/domain/goodtoknow"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p className="small-card-title">Good To Know Category</p>
              <p className="small-description">
                Supplementary knowledge and useful tips that add value to your
                understanding of our field, though not essential for
                foundational comprehension.
              </p>
              <p className="small-article-count">
                6 Articles
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="small-arrow-icon"
                />
              </p>
            </Link>
          </div>
          <div className="small-card">
            <p className="small-card-title">Miscellaneous</p>
            <p className="small-description">
              Useful tips that add value to your understanding of our field,
              though not essential for foundational comprehension.
            </p>
            <p className="small-article-count">
              6 Articles
              <FontAwesomeIcon
                icon={faArrowRight}
                className="small-arrow-icon"
              />
            </p>
          </div>
        </div>
        <div className="small-card-container">
          <div
            className="small-card"
            style={{ textAlign: "right", backgroundColor: "#f0f0f0" }}
          >
            <p style={{ color: "#4D5A66", fontWeight: "bold" }}>
              Previous Post
            </p>
            <p className="small-description">
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: "auto" }}
              />
              Essential insights
            </p>
          </div>
          <div
            className="small-card"
            style={{ textAlign: "left", transition: "background-color 0.3s" }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#fff")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
          >
            <p style={{ color: "#4D5A66", fontWeight: "bold" }}>Next Post</p>
            <p className="small-description">
              Essential insights{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "auto" }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="search-bar">
          <input type="text" placeholder="Search..." className="search-input" />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      </div>
    </div>
  );
};

export default DomainConcepts;
