import { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import "./LoginStyles.css";

const Signup = () => {
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Handle form submission logic here
    console.log(e);
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;
    const email = e.target.elements.email.value;
    const mobNo = e.target.elements.phoneNo.value;

    console.log("Username:", username);
    console.log("Password:", password);
    console.log("Email:", email);
    let formData = {
      name: username,
      password: password,
      email: email,
      mobNo: mobNo,
    };
    try {
      const response = await axios.post(`${baseUrl}/signup`, formData);
      console.log("Response:", response.data);
      toast.success("Registration Successful !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Handle successful response here

      history("/verify", { state: { data: email } });
    } catch (error) {
      toast.error("Something went Wrong, Please try again !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
      // Handle error here
    }
  };
  return (
    <div style={{ backgroundColor: "#4b5d7a" }}>
      <Button
        type="primary"
        shape="round"
        title="Back "
        className="my-5 mx-3"
        icon={<LeftOutlined />}
        onClick={() => history("/")}
      >
        Go Back
      </Button>
      <div className="login-page-container ">
        <div className="wrapper">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>
                Username
              </p>
              <div
                className="input-box"
                style={{ marginTop: "0rem", marginBottom: "1rem" }}
              >
                <input
                  id="username"
                  type="text"
                  placeholder="deniel123"
                  required
                />
                <i className="bx bxs-user"></i>
              </div>
            </div>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>
                Password
              </p>
              <div
                className="input-box"
                style={{ marginTop: "0rem", marginBottom: "15px" }}
              >
                <input
                  id="password"
                  type="password"
                  placeholder={"• • • • • • •"}
                  required
                />
                <i className="bx bxs-lock-alt"></i>
              </div>
            </div>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>Email</p>
              <div className="input-box" style={{ marginTop: "0rem" }}>
                <input
                  id="email"
                  type="email"
                  placeholder={"deniel123@gmail.com"}
                  required
                />
                <i className="bx bxs-lock-alt"></i>
              </div>
            </div>
            <div className="input-group">
              <p style={{ marginBottom: "10px", fontWeight: "600" }}>
                Mobile Number
              </p>
              <div className="input-box" style={{ marginTop: "0rem" }}>
                <input
                  id="phoneNo"
                  type="number"
                  placeholder={"9999999999"}
                  required
                />
                <i className="bx bxs-lock-alt"></i>
              </div>
            </div>
            <button type="submit" className="btn">
              SignUp
            </button>
            <div className="register-link">
              <p>
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Signup;
