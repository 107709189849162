// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-card {
    box-sizing: border-box;
    background: rgba(217, 217, 217, 0.58);
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    font-weight: bolder;
  }
  
  .time-card:hover {
    border: 1px solid #387ed1;
    transform: scale(1.05);
  }
  
  .time-card:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Landing/BookACall.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qCAAqC;IACrC,uBAAuB;IACvB,8CAA8C;IAC9C,kCAA0B;YAA1B,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,sCAAsC;EACxC","sourcesContent":[".time-card {\n    box-sizing: border-box;\n    background: rgba(217, 217, 217, 0.58);\n    border: 1px solid white;\n    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);\n    backdrop-filter: blur(6px);\n    border-radius: 17px;\n    text-align: center;\n    cursor: pointer;\n    transition: all 0.5s;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    font-weight: bolder;\n  }\n  \n  .time-card:hover {\n    border: 1px solid #387ed1;\n    transform: scale(1.05);\n  }\n  \n  .time-card:active {\n    transform: scale(0.95) rotateZ(1.7deg);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
