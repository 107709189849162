import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Typography, Table, Tabs } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title as ChartTitle, Tooltip, Legend, ArcElement } from 'chart.js';
import './modal.css'
const { Title } = Typography;
const { TabPane } = Tabs;

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ArcElement
);

const AggregateContent = () => {
  const barData = {
    labels: ['Financial Strength', 'Governance', 'Complexity of instrument', 'Market presence'],
    datasets: [
      {
        label: 'Items (No)',
        data: [5, 5, 5, 6],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Sub-items (No)',
        data: [5, 8, 5, 6],
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  const pieData = {
    labels: ['Financial Strength', 'Governance', 'Complexity of instrument', 'Market presence'],
    datasets: [
      {
        data: [65, 10, 10, 15],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40'],
      },
    ],
  };

  const barOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const columns = [
    { title: 'No', dataIndex: 'no', key: 'no', align: 'center' },
    { title: 'Parameter', dataIndex: 'parameter', key: 'parameter' },
    { title: 'Items (No)', dataIndex: 'items', key: 'items', align: 'center' },
    { title: 'Sub-items (No)', dataIndex: 'subItems', key: 'subItems', align: 'center' },
    { title: 'Max Points', dataIndex: 'maxPoints', key: 'maxPoints', align: 'center' },
    { title: 'Weightage', dataIndex: 'weightage', key: 'weightage', align: 'center' },
    { title: 'Weighted Total', dataIndex: 'weightedTotal', key: 'weightedTotal', align: 'center' },
  ];

  const data = [
    { key: '1', no: '1', parameter: 'Financial Strength', items: '5', subItems: '5', maxPoints: '100', weightage: '65%', weightedTotal: '65' },
    { key: '2', no: '2', parameter: 'Governance', items: '5', subItems: '8', maxPoints: '100', weightage: '10%', weightedTotal: '10' },
    { key: '3', no: '3', parameter: 'Complexity of instrument', items: '5', subItems: '5', maxPoints: '100', weightage: '10%', weightedTotal: '10' },
    { key: '4', no: '4', parameter: 'Market presence', items: '6', subItems: '6', maxPoints: '100', weightage: '15%', weightedTotal: '15' },
    { key: '5', no: '', parameter: '', items: '21', subItems: '24', maxPoints: '400', weightage: '', weightedTotal: '100' },
  ];

  return (
    <div className="aggregate-content-container">
      <Title level={4} className="chart-title">
        Securities Comprehensive Assessment Number (SCAN)
      </Title>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Items and Sub-items" key="1">
          <div className="chart-container">
            <Title level={5} className="chart-title">
              Items and Sub-items per Parameter
            </Title>
            <Bar data={barData} options={barOptions} />
          </div>
        </TabPane>
        <TabPane tab="Weightage Distribution" key="2">
          <div className="chart-container">
            <Title level={5} className="chart-title">
              Weightage Distribution
            </Title>
            <div style={{ width: '300px', margin: '0 auto' }}>
              <Pie data={pieData} />
            </div>
          </div>
        </TabPane>
        <TabPane tab="Details" key="3">
          <div className="table-container">
            <Title level={5} className="chart-title">
              Details
            </Title>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              size="middle"
              bordered
              style={{
                width: '100%',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
              className="custom-table"
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AggregateContent;
