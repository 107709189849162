import React, { useState } from 'react';
import { Typography, Table, Card, Button, Modal } from 'antd';
import './modal.css'; 

const { Title } = Typography;

// Define columns for the main table
const mainColumns = (handleRowClick) => [
  { title: 'Market Presence', dataIndex: 'presence', key: 'presence' },
  { title: 'Max Points', dataIndex: 'maxPoints', key: 'maxPoints' },
  { title: 'Weightage', dataIndex: 'weightage', key: 'weightage' },
  { title: 'Sub-Items', dataIndex: 'subItems', key: 'subItems' },
  { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
  { title: 'Interval', dataIndex: 'interval', key: 'interval' },
];

const mainData = [
  { key: '1', presence: 'Size of issuance', maxPoints: '20', weightage: '20%', subItems: '1', remarks: 'Issue size', interval: 'One time' },
  { key: '2', presence: 'Outstanding issuances', maxPoints: '20', weightage: '20%', subItems: '1', remarks: 'Supply of bonds', interval: 'Regular' },
  { key: '3', presence: 'Secondary market T/o', maxPoints: '20', weightage: '20%', subItems: '1', remarks: 'Liquidity', interval: 'Regular' },
  { key: '4', presence: 'Capital market history', maxPoints: '20', weightage: '20%', subItems: '1', remarks: 'Aggregate fund raising through equity and debt (including offshore and domestic markets)', interval: 'Regular' },
  { key: '5', presence: 'Aggregate Borrowings', maxPoints: '10', weightage: '10%', subItems: '1', remarks: 'Aggregating loans and bond route', interval: 'Regular' },
  { key: '6', presence: 'Bond issuances as % of aggregate issuance', maxPoints: '10', weightage: '10%', subItems: '1', remarks: 'Critical source for raising debt finance through bonds', interval: 'Regular' },
  { key: '7', presence: 'Total', maxPoints: '100', weightage: '100%', subItems: '6' },
];

// Define columns for each sub-table
const subTableColumns = (title) => [
  { title, dataIndex: 'item', key: 'item' },
  { title: 'Points', dataIndex: 'points', key: 'points' },
];

// Define data for each sub-table
const subTableData = {
  'Size of issuance': [
    { key: '1', item: '>INR 1000 Cr', points: '20' },
    { key: '2', item: '501-1000', points: '18' },
    { key: '3', item: '100-500', points: '12' },
    { key: '4', item: '<100', points: '6' },
  ],
  'Outstanding issuances': [
    { key: '1', item: '>INR 10000 Cr', points: '20' },
    { key: '2', item: '5001-10000', points: '18' },
    { key: '3', item: '1001-5000', points: '12' },
    { key: '4', item: '<1000', points: '6' },
  ],
  'Secondary market T/o': [
    { key: '1', item: '>INR 1000 Cr', points: '20' },
    { key: '2', item: '501-1000', points: '18' },
    { key: '3', item: '100-500', points: '12' },
    { key: '4', item: '<100', points: '6' },
  ],
  'Capital market history': [
    { key: '1', item: 'Equity+Debt+Offshore', points: '20' },
    { key: '2', item: 'Equity+Debt', points: '18' },
    { key: '3', item: 'Public Issue Debt', points: '12' },
    { key: '4', item: 'WDM', points: '6' },
  ],
  'Aggregate Borrowings': [
    { key: '1', item: '>INR 10000 Cr', points: '10' },
    { key: '2', item: '5001-10000', points: '8' },
    { key: '3', item: '1001-5000', points: '6' },
    { key: '4', item: '<1000', points: '0' },
  ],
  'Bond issuances as % of aggregate issuance': [
    { key: '1', item: '>50%', points: '10' },
    { key: '2', item: '25%-50%', points: '8' },
    { key: '3', item: '10%-25%', points: '6' },
    { key: '4', item: '<10%', points: '2' },
  ],
};

const MarketActivity = () => {
  const [visible, setVisible] = useState(null);

  const handleRowClick = (record) => {
    setVisible(record.presence);
  };

  const handleCloseModal = () => {
    setVisible(null);
  };

  return (
    <div className="market-activity-container">
      <Title level={4} className="section-title">
        Market Activity Assessment
      </Title>
      
      <Card className="main-table-card">
        <Table
          columns={mainColumns(handleRowClick)}
          dataSource={mainData}
          pagination={false}
          bordered
          className="main-table"
          rowKey="key"
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Open modal on row click
          })}
        />
      </Card>

      {/* Render modals for each sub-table */}
      {Object.keys(subTableData).map((key) => (
        <Modal
          key={key}
          title={key}
          visible={visible === key}
          onCancel={handleCloseModal}
          footer={[
            <Button key="back" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleCloseModal}>
              OK
            </Button>,
          ]}
          width={500} 
          style={{ top: 20 }}
        >
          <Table
            columns={subTableColumns(key)}
            dataSource={subTableData[key]}
            pagination={false}
            bordered
            className="sub-table"
          />
        </Modal>
      ))}
    </div>
  );
};

export default MarketActivity;
