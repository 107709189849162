import React, { useState } from "react";
import "../shared/staticStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Sidebar";
import App from "../../App";

const topics = [
  {
    title: "Offer Document",
    content: [
      "An offer document is a comprehensive legal document that provides potential investors with detailed information about a securities offering. It typically includes: - Company Information: Details about the issuing company, its business operations, management team, financial performance, and future prospects. - Terms of the Offering: Specific terms and conditions of the securities being offered, such as the type of securities (stocks, bonds, etc.), price, quantity, and any special features or rights attached to the securities. -Risk Factors: Disclosure of the potential risks associated with investing in the securities, including market risks, industry-specific risks, regulatory risks, and company-specific risks. - Use of Proceeds: Explanation of how the proceeds from the offering will be used by the company, whether for business expansion, debt repayment, working capital, or other purposes. - Legal and Regulatory Disclosures: Compliance with regulatory requirements and disclosures of any legal or regulatory issues that may impact the investment decision.",
    ],
  },
  {
    title: "Term Sheet",
    content: [
      "A term sheet is a concise summary document that outlines the key terms and conditions of a proposed investment or financing arrangement. It typically includes: -Transaction Overview: Description of the proposed transaction, including the type of investment or financing, amount involved, and parties involved (investors, issuers, etc.). -Valuation and Terms: Valuation of the company or assets, pricing of the securities, and specific terms and conditions of the investment, such as equity ownership percentage, dividend rights, or interest rates. -Investor Rights and Protections: Rights and protections afforded to investors, such as board representation, veto rights, anti-dilution provisions, or exit strategies. -Conditions Precedent: Conditions that must be satisfied before the transaction can be completed, such as regulatory approvals, due diligence, or financing arrangements. - Confidentiality and Exclusivity: Agreements regarding confidentiality of information and exclusivity of negotiations for a specified period.",
    ],
  },
  {
    title: "Security",
    content: [
      "In finance, a security refers to a tradable financial instrument that represents ownership or debt obligations of an entity and has monetary value. Securities can take various forms, including: - Equity Securities: Represent ownership interests in a company and include stocks or shares of common or preferred stock. Equity securities provide shareholders with ownership rights, such as voting rights and participation in the company's profits through dividends. - Debt Securities: Represent debt obligations of an entity and include bonds, debentures, or promissory notes. Debt securities entitle investors to receive periodic interest payments and repayment of principal amount at maturity. - Derivative Securities: Financial contracts whose value is derived from the value of an underlying asset, index, or benchmark. Examples include options, futures, swaps, and forwards. - Hybrid Securities: Combine features of both debt and equity securities, such as convertible bonds, which allow bondholders to convert their bonds into shares of stock.",
    ],
  },
  {
    title: "Debenture Trust Deed",
    content: [
      "A debenture trust deed is a legal document that governs the terms and conditions of a debenture issuance and establishes the rights and obligations of the issuer, debenture holders, and trustee. It typically includes: -Issuer Information: Details about the issuing company, including its name, address, legal status, and corporate structure. -Terms of the Debentures: Specifications of the debentures being issued, such as their nominal value, interest rate, maturity date, redemption provisions, and any special rights or features. - Security Arrangements: Description of any security or collateral provided by the issuer to secure the debentures, such as a charge or mortgage over assets. - Role of the Trustee: Appointment of a trustee to represent the interests of debenture holders, monitor compliance with the debenture terms, and take appropriate actions in the event of default. -Covenants and Undertakings: Obligations and commitments of the issuer, such as maintaining certain financial ratios, providing regular financial reports, or obtaining consent for certain corporate actions.",
    ],
  },
  {
    title: "Structured Obligation",
    content: [
      "A structured obligation refers to a financial obligation that has been customized or structured to meet specific investment objectives or risk management needs. These obligations are typically created by financial institutions and may involve the pooling of assets or cash flows to create complex financial products tailored to investors' preferences. Examples of structured obligations include: -Collateralized Debt Obligations (CDOs): Securities backed by pools of debt instruments, such as bonds, loans, or mortgages, which are divided into different tranches with varying levels of risk and return. -Asset-backed Securities (ABS): Securities backed by pools of assets, such as auto loans, credit card receivables, or mortgages, which generate cash flows used to pay investors. - Structured Notes: Debt securities whose returns are linked to the performance of underlying assets, indices, or benchmarks, with customized risk-return profiles and features. -Credit Default Swaps (CDS): Derivative contracts that provide protection against credit risk by transferring the risk of default on a specified debt obligation from one party to another.",
    ],
  },
  {
    title: "Credit Enhancement",
    content: [
      "Credit enhancement refers to any mechanism or arrangement designed to improve the credit quality of a financial instrument or investment, thereby reducing the risk of default and enhancing its marketability. Credit enhancement techniques aim to mitigate credit risk and provide investors with greater confidence in the issuer's ability to fulfill its obligations. Common forms of credit enhancement include: -Guarantees: Agreements by third-party entities to repay the debt obligation in the event of default by the issuer, providing an additional layer of security to investors. - Insurance: Purchase of insurance policies to protect against specific risks, such as default, loss of principal, or adverse changes in market conditions. - Collateral: Pledging of specific assets or securities as collateral to secure the debt obligation, reducing the risk of loss for investors in the event of default. -Letters of Credit: Issuance of letters of credit by financial institutions to guarantee payment to investors, providing assurance of timely repayment of principal and interest. - Subordination: Structuring debt securities into different tranches or layers of seniority, where senior tranches have priority claim over cash flows or assets ahead of junior tranches, enhancing the credit quality of senior securities.",
    ],
  },
  {
    title: "Dematerialization",
    content: [
      "Dematerialization is the process of converting physical certificates of securities into electronic or digital form, thereby eliminating the need for paper certificates and facilitating electronic trading and settlement of securities. Dematerialization involves: -Opening a Demat Account: Investors must open a demat account with a depository participant (DP), who acts as an intermediary between the investor and the central depository. - Surrendering Physical Certificates: Physical certificates of securities are surrendered to the DP for dematerialization, where they are verified and converted into electronic form. - Crediting Electronic Holdings: Once the dematerialization process is complete, the investor's electronic holdings of securities are credited to their demat account, where they can be held, bought, or sold electronically. -Electronic Trading and Settlement: Investors can trade electronic securities on stock exchanges or over-the-counter markets through their demat accounts, with transactions settled electronically through the clearing and settlement system.",
    ],
  },
  {
    title: "Primary Market",
    content: [
      "The primary market, also known as the new issue market, is where newly issued securities are bought and sold for the first time by issuers and investors. It is the primary venue for companies to raise capital through offerings of stocks, bonds, or other financial instruments. Key features of the primary market include: -Capital Formation: Companies raise funds for business expansion, capital investment, research and development, or debt refinancing by issuing new securities to investors. -Initial Public Offerings (IPOs): Companies offer shares of stock to the public for the first time, allowing investors to become shareholders and providing liquidity to existing shareholders. - Debt Offerings: Issuers raise funds by issuing bonds, debentures, or other debt securities to investors, who receive fixed or floating interest payments and repayment of principal at maturity. - Regulatory Oversight: Primary market offerings are subject to regulatory requirements and oversight by securities regulators to ensure transparency, fairness, and investor protection.",
    ],
  },
  {
    title: "Secondary Market",
    content: [
      "The secondary market, also known as the aftermarket, is where previously issued securities are bought and sold among investors after their initial issuance in the primary market. It provides liquidity to investors by enabling them to buy and sell existing securities on stock exchanges or over-the-counter markets. Key characteristics of the secondary market include: -Trading of Existing Securities: Investors buy and sell previously issued securities, such as stocks, bonds, options, or derivatives, with other investors based on supply and demand. - Price Discovery: Market forces determine the prices of securities in the secondary market through the interaction of buyers and sellers, reflecting changes in investor sentiment, market conditions, and fundamental factors. - Liquidity: Investors can easily convert their investments into cash by selling securities in the secondary market, as there is typically an active marketplace with multiple buyers and sellers. - Efficient Allocation of Capital: Secondary market trading enables investors to adjust their investment portfolios, allocate capital to new opportunities, and react to changing market conditions in a timely manner.",
    ],
  },
  {
    title: "Public Issue",
    content: [
      "A public issue, also known as a public offering or an initial public offering (IPO), is the process by which a company offers its securities, such as stocks or bonds, to the general public for purchase. Public issues are conducted in the primary market and involve the sale of securities to individual and institutional investors. Key aspects of a public issue include: - Share Offering: Companies offer shares of their stock to the public for the first time, allowing investors to become shareholders and participate in the company's ownership and growth. - Prospectus: Issuers prepare a prospectus, or offer document, which provides detailed information about the company, its business operations, financial performance, risks, and terms of the offering. - Underwriting: Investment banks or underwriters assist the company in structuring the offering, pricing the securities, and marketing them to potential investors. They may purchase the securities from the company and resell them to investors at a profit. - Listing on Stock Exchange: After the securities are sold to the public, the company's shares may be listed and traded on a stock exchange, providing liquidity to investors and enabling ongoing trading in the secondary market.",
    ],
  },
  {
    title: "Private Placement",
    content: [
      "Private placement involves the sale of securities to a select group of investors, such as institutional investors, accredited investors, or high-net-worth individuals, without making a public offering. Private placements are conducted in the primary market and may involve the issuance of stocks, bonds, or other financial instruments. Key characteristics of private placements include: - Limited Offering : Securities are offered to a limited number of investors, often through direct negotiations with potential buyers rather than through a public solicitation. - *Exemption from Registration*: Private placements are exempt from certain regulatory requirements and disclosure obligations that apply to public offerings, allowing issuers to raise capital more quickly and with less administrative burden. -  Sophisticated Investors : Private placements are typically targeted at institutional investors or wealthy individuals who have the expertise and resources to evaluate investment opportunities and bear the associated risks. -Customized Terms: Issuers have greater flexibility to negotiate the terms and conditions of the offering with investors, including pricing, security features, governance rights, and exit strategies.",
    ],
  },
];

const GoodToKnow = () => {
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [selectedSubheaderIndex, setSelectedSubheaderIndex] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleNextContent = () => {
    if (
      selectedSubheaderIndex <
      topics[selectedTopicIndex].content.length - 1
    ) {
      setSelectedSubheaderIndex(selectedSubheaderIndex + 1);
    }
  };

  const handlePreviousContent = () => {
    if (selectedSubheaderIndex > 0) {
      setSelectedSubheaderIndex(selectedSubheaderIndex - 1);
    }
  };

  const handleCloseSidebar = () => {
    setSelectedTopicIndex(null);
    setSelectedSubheaderIndex(null);
    setSidebarOpen(false);
  };

  const filteredTopics = topics.filter((topic) =>
    topic.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="Start">
      {/* <Sidebar /> */}
      <div className="main-content">
        <div>
          <div className="tabs">
            <button>Visit Website</button>
            <button>Blog</button>
            <button>Contacts</button>
          </div>
          <hr />
          <h4 style={{ fontWeight: 200 }}>
            Home / Knowledge Base / Domain Concepts / Good To Know
          </h4>
          <h1>Good To Know Category</h1>
          <p style={{ marginTop: "1rem" }}>
            The Must Know Category represents an indispensable reservoir of
            crucial insights and foundational knowledge that are absolutely
            imperative for grasping, comprehending, and mastering the
            fundamental principles, essential concepts, and intricate
            intricacies within our domain.
          </p>
        </div>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>

        <div className="small-card-container">
          {filteredTopics.map((topic, index) => (
            <div
              key={index}
              className="small-card"
              onClick={() => {
                setSelectedTopicIndex(index);
                setSelectedSubheaderIndex(0);
                setSidebarOpen(true);
              }}
            >
              <p className="small-card-title">{topic.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="right-side">
        {sidebarOpen && (
          <div className="sidebar-panel">
            <div
              className="sidebar-panel-content"
              style={{ maxHeight: "90vh", overflowY: "auto" }}
            >
              <span
                className="sidebar-panel-close"
                onClick={handleCloseSidebar}
              >
                &times;
              </span>
              {sidebarOpen && <h2>{topics[selectedTopicIndex].title}</h2>}
              {sidebarOpen && (
                <p>
                  {topics[selectedTopicIndex].content[selectedSubheaderIndex]}
                </p>
              )}
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <button onClick={handlePreviousContent}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={handleNextContent}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoodToKnow;
