import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/Login";
import SignUp from "../components/Signup";
const Routers = (props) => (
    <>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
        </Routes>
    </>
);

export default Routers;
