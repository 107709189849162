import React, { useState } from "react";
import { Modal, Menu } from "antd";
import AggregateContent from "./AggregateContent";
import FinancialStrength from "./FinancialStrength";
import Governance from "./Governance";
import Complexity from "./Complexity";
import MarketActivity from "./MarketActivity";


const MoreInfoModal = ({ visible, onClose }) => {
  const [selectedTab, setSelectedTab] = useState("aggregate");

  const handleMenuClick = (e) => {
    setSelectedTab(e.key);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "aggregate":
        return <AggregateContent />;
      case "financial-strength":
        return <FinancialStrength/>;
      case "governance":
        return <Governance/>;
      case "complexity":
        return <Complexity/>;
      case "market-activity":
        return <MarketActivity/>;
      default:
        return <AggregateContent />;
    }
  };

  return (
    <Modal
      title="More Information"
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      width={900} 
    >
      <div style={{ display: "flex" }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["aggregate"]}
          style={{ width: 200 }}
          onClick={handleMenuClick}
        >
          <Menu.Item key="aggregate">Aggregate</Menu.Item>
          <Menu.Item key="financial-strength">Financial Strength</Menu.Item>
          <Menu.Item key="governance">Governance</Menu.Item>
          <Menu.Item key="complexity">Complexity</Menu.Item>
          <Menu.Item key="market-activity">Market Activity</Menu.Item>
        </Menu>
        <div style={{ flex: 1, padding: "0 24px" }}>
          {renderContent()}
        </div>
      </div>
    </Modal>
  );
};

export default MoreInfoModal;
