import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Typography, Card, Divider } from "antd";
import axios from "axios";
import { ArrowRightOutlined, DownloadOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons/lib";
const { Title } = Typography;

const Admin = () => {
  const [loadings, setLoadings] = useState([]);
  const stopLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };

  const transferDataDataSource = [
    {
      key: "9",
      title: "Ratings",
      name: "ratings",
    },
    {
      key: "12",
      title: "News",
      name: "news",
    },
    {
      key: "10",
      title: "Financials and Risk Factors",
      name: "financial",
    },
    {
      key: "11",
      title: "Rational",
      name: "rational",
    },

    {
      key: "13",
      title: "NSE Debt ISIN List",
      name: "isins",
    },
    {
      key: "17",
      title: "Credit Notes and Bond Cash Flow",
      name: "credit",
    },
    {
      key: "14",
      title: "NSE Debt Announcement",
      name: "debt-announcement",
    },
    {
      key: "15",
      title: "NSE Bonds Traded In Capital Market",
      name: "capital-volume",
    },
    {
      key: "16",
      title: "BSE Corporate Bonds",
      name: "bse-corporate-bonds",
    },
    {
      key: "18",
      title: "Update Yield To Maturity",
      name: "ytm",
    },
    {
      key: "20",
      title: "Save Credit rating Details from IndiaBondInfo",
      name: "credit-ratings",
    },
    {
      key: "25",
      title: "Nse Debt Market Trade Repository",
      name: "trade-repository",
    },
    {
      key: "26",
      title: "BSE OTC Trade Repository",
      name: "bse-trade-repository",
    },
  ];

  const fetchRatingsDataSource = [
    {
      key: "0",
      title: "Care Rating",
      name: "care",
    },
    {
      key: "1",
      title: "Crisil Rating",
      name: "crisil",
    },
    {
      key: "2",
      title: "Fitch Rating",
      name: "fitch",
    },
    {
      key: "3",
      title: "ICRA Rating",
      name: "icra",
    },
    {
      key: "4",
      title: "India Rating",
      name: "india",
    },
  ];
  const fetchRationalDataSource = [
    {
      key: "5",
      title: "Fitch Rationale",
      name: "fitch_rationale",
    },
    {
      key: "6",
      title: "India Rationale",
      name: "india_rationale",
    },
  ];

  const fetchNewsDataSource = [
    {
      key: "7",
      title: "Economics Times",
      name: "news_et",
    },
    {
      key: "8",
      title: "Business standard",
      name: "news_bs",
    },
    {
      key: "8",
      title: "Financial Express",
      name: "news_fe",
    },
    {
      key: "8",
      title: "Livemint",
      name: "news_lm",
    },
    {
      key: "8",
      title: "Moneycontrol",
      name: "news_mc",
    },
  ];

  const scanDataSource = [
    {
      key: "17",
      title: "Financial Strength Data Save",
      name: "financial-strength",
    },
    {
      key: "18",
      title: "Governance Data Save",
      name: "governance",
    },
    {
      key: 19,
      title: "Complexity Data Save",
      name: "complexity",
    },
    {
      key: 20,
      title: "Market Activity Data Save",
      name: "market-activity",
    },
  ];

  const nseDataSource = [
    {
      key: "19",
      title: "NSE Announcement/Debt",
      name: "nse_debt",
    },
    {
      key: "20",
      title: "NSE trading volume",
      name: "nse_trading_vol",
    },
    {
      key: "21",
      title: "NSE List of non compliant Companies - Equities",
      name: "nse_non_compliant_eq",
    },
    {
      key: "22",
      title: "NSE List of non compliant Companies - Debt",
      name: "nse_non_compliant_debt",
    },
    {
      key: "23",
      title: "NSE Yield to Maturity",
      name: "nse_ytm",
    },
    {
      key: "24",
      title: "NSDL India Bond Info Details",
      name: "nsdl_indiabondinfo",
    },
  ];

  const transferDataColumns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "action",
      render: (text, data) => (
        <Button
          type="primary"
          onClick={() => transferData(text)}
          icon={<ArrowRightOutlined />}
          size="large"
          loading={loadings[data.key]}
        >
          Transfer Data
        </Button>
      ),
    },
  ];

  const fetchDataColumns = [
    {
      title: "Name",
      width: 500,
      align: "center",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "action",
      render: (text, data) => (
        <Button
          type="primary"
          onClick={() => fetchData(text)}
          icon={<DownloadOutlined />}
          size="large"
          loading={loadings[data.key]}
        >
          Run Bot
        </Button>
      ),
    },
  ];
  const scanDataColumns = [
    {
      title: "Name",
      width: 500,
      align: "center",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "action",
      render: (text, data) => (
        <Button
          type="primary"
          onClick={() => saveScanData(text)}
          icon={<DownloadOutlined />}
          size="large"
          loading={loadings[data.key]}
        >
          Run Bot
        </Button>
      ),
    },
  ];

  const ratings = ["care", "crisil", "india", "fitch", "icra"];
  const news = ["mint", "bs", "financial", "economics"];
  const rational = ["india", "fitch"];

  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const botsBaseUrl = process.env.REACT_APP_BOTS_API_URL;

  const transferData = async (type) => {
    if (type === "ratings") {
      enterLoading(9);
      saveRatings();
    } else if (type === "financial") {
      enterLoading(10);
      saveFinan();
    } else if (type === "rational") {
      enterLoading(11);
      saveRational();
    } else if (type === "news") {
      enterLoading(12);
      saveNews();
    } else if (type === "isins") {
      enterLoading(13);
      saveBondIsins();
    } else if (type === "credit") {
      enterLoading(17);
      saveCreditNotesAndCashFlow();
    } else if (type === "ytm") {
      enterLoading(18);
      updateYieldToMaturity();
    } else if (type === "credit-ratings") {
      enterLoading(20);
      saveCreditRatingsFromIndiaBondInfo();
    } else if (type === "trade-repository") {
      enterLoading(25);
      saveDebtMarketTradeRepository();
    } else if (type === "bse-trade-repository") {
      enterLoading(26);
      saveOTCTradeRepository();
    } else if (type === "debt-announcement") {
      enterLoading(14);
      saveDebtAnnouncement();
    } else if (type === "capital-volume") {
      enterLoading(15);
      saveCapitalVolume();
    } else if (type === "bse-corporate-bonds") {
      enterLoading(16);
      saveBseCorporateBonds();
    }
  };

  const fetchData = async (type) => {
    runBot(type);

    // if (type === "care") {
    //   enterLoading(0);
    //   fetchCareRatings();
    // } else if (type === "crisil") {
    //   enterLoading(1);
    //   fetchCrisilRatings();
    // } else if (type === "fitch") {
    //   enterLoading(2);
    //   fetchFitchRatings();
    // } else if (type === "icra") {
    //   enterLoading(3);
    //   fetchIcraRatings();
    // } else if (type === "india") {
    //   enterLoading(4);
    //   fetchIndiaRatings();
    // } else if (type === "fitch-rationale") {
    //   enterLoading(5);
    //   fetchFitchRationale();
    // } else if (type === "india-rationale") {
    //   enterLoading(6);
    //   fetchIndiaRationale();
    // } else if (type === "economics-news") {
    //   enterLoading(7);
    //   fetchEconomicsNews();
    // } else if (type === "financial-data") {
    //   enterLoading(8);
    //   fetchFinancialData();
    // }
  };

  const runBot = async (type) => {
    try {
      const response = await axios.get(`${botsBaseUrl}/${type}/`);
      if (response && response.data) {
        console.log("Bot Ran Successfully");
        alert("Bot Ran Successfully");
      }
    } catch (error) {
      console.error("Error Running Bot:", error);
      alert("Error Occurred");
    }
  };

  const saveRatings = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      for (let i = 0; i < ratings.length; i++) {
        const rate = ratings[i];
        const response = await axios.get(
          `${baseUrl}/${rate}/rating/save`, // Changed URL to include the rating
          { headers }
        );
        if (response && response.data) {
          console.log("New Data Saved for Ratings");
          alert("New Data Saved for Ratings");
        }
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(9);
    }
  };
  const updateYieldToMaturity = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/nse/ytm/update`, {
        headers,
      });
      if (response && response.data) {
        console.log("New Data Saved for Financials and Risk");
        alert("New Data Saved for Financials and Risk factors");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(10);
    }
  };

  const saveCreditRatingsFromIndiaBondInfo = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(
        `${baseUrl}/nse/credit-rating-details/save`,
        {
          headers,
        }
      );
      if (response && response.data) {
        console.log("New Data Saved for Financials and Risk");
        alert("New Data Saved for Financials and Risk factors");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(20);
    }
  };

  const saveFinan = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/financial/save`, {
        headers,
      });
      const response1 = await axios.get(`${baseUrl}/nse/risk/save`, {
        headers,
      });
      if (response && response.data && response1 && response1.data) {
        console.log("New Data Saved for Financials and Risk");
        alert("New Data Saved for Financials and Risk factors");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(10);
    }
  };

  const saveScanData = async (data) => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/scan/all`, {
        headers,
      });

      if (response && response.data) {
        console.log("New Data Saved for Financials and Risk");
        alert("New Data Saved for Financials and Risk factors");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(10);
    }
  };
  const saveRational = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };

      // Iterate over the ratings array
      for (let i = 0; i < rational.length; i++) {
        const rate = rational[i];

        const response = await axios.get(
          `${baseUrl}/${rate}/rational/save`, // Changed URL to include the news
          { headers }
        );

        // Assuming your API returns a JSON response
        if (response && response.data) {
          console.log("New Data Saved for News:" + rate);
          alert("New Data Saved for News ");
        }
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(11);
    }
  };

  const saveNews = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };

      // Iterate over the ratings array
      for (let i = 0; i < news.length; i++) {
        const rate = news[i];

        const response = await axios.get(
          `${baseUrl}/news/${rate}/save`, // Changed URL to include the news
          { headers }
        );

        // Assuming your API returns a JSON response
        if (response && response.data) {
          console.log("New Data Saved for News:" + rate);
          alert("New Data Saved for News ");
        }
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(12);
    }
  };
  const saveCreditNotesAndCashFlow = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(
        `${baseUrl}/nse/save/credit-note/Power Finance Corporation Limited`, // Changed URL to include the news
        { headers }
      );
      const response1 = await axios.get(
        `${baseUrl}/financial/save/cash-flow`, // Changed URL to include the news
        { headers }
      );

      if (response && response.data) {
        console.log("New Data Saved for Bonds:");
        alert("New Data Saved for Bonds");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(13);
    }
  };

  const saveBondIsins = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(
        `${baseUrl}/nse/save/pdf-data/Power Finance Corporation Limited`, // Changed URL to include the news
        { headers }
      );
      // Assuming your API returns a JSON response
      if (response && response.data) {
        console.log("New Data Saved for Bonds:");
        alert("New Data Saved for Bonds");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(13);
    }
  };
  const saveOTCTradeRepository = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/bse/save/otc`, {
        headers,
      });
      if (response && response.data) {
        console.log("New Data Saved for Debt Market Trade Repository");
        alert("New Data Saved for Debt Market Trade Repository");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(26);
    }
  };
  const saveDebtMarketTradeRepository = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/nse/trade/save`, {
        headers,
      });
      if (response && response.data) {
        console.log("New Data Saved for Debt Market Trade Repository");
        alert("New Data Saved for Debt Market Trade Repository");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(25);
    }
  };
  const saveDebtAnnouncement = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await fetch(
        `${baseUrl}/nse-announcement/save/REC_Limited_announcements`,
        {
          headers: {
            "ngrok-skip-browser-warning": "application/json",

            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Set Authorization header with token
          },
        }
      );
      const response1 = await fetch(
        `${baseUrl}/nse-announcement/save/Power_Finance_Corporation_announcements`,
        {
          headers: {
            "ngrok-skip-browser-warning": "application/json",

            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Set Authorization header with token
          },
        }
      );
      if (response && response.data) {
        console.log("New Data Saved for Debt Announcement");
        alert("New Data Saved for Debt Announcement");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(14);
    }
  };

  const saveCapitalVolume = async () => {
    try {
      const response = await axios.get(`${baseUrl}/nse-trading-vol/save`);
      if (response && response.data) {
        console.log("New Data Saved for Capital Volume");
        alert("New Data Saved for Capital Volume");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(15);
    }
  };

  const saveBseCorporateBonds = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        // You can add more headers if needed
      };
      const response = await axios.get(`${baseUrl}/corporatebonds/save`, {
        headers,
      });
      if (response && response.data) {
        console.log("New Data Saved for BSE Corporate Bonds");
        alert("New Data Saved for BSE Corporate Bonds");
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
      stopLoading(16);
    }
  };

  return (
    <div>
      <Button
        type="link"
        icon={<LeftOutlined />}
        onClick={() => history("/")}
        style={{
          position: "absolute",
          left: "5px",
          top: "0px",
        }}
      >
        Back
      </Button>

      <div className="container-xxl">
        <div className="text-7xl">Bots :</div>

        <div className="d-flex justify-content-center">
          <Card>
            <Table
              columns={fetchDataColumns}
              dataSource={fetchRatingsDataSource}
              title={() => <Title level={3}>Ratings</Title>}
              pagination={false}
              rowKey="name"
            />
          </Card>

          <div>
            <Card>
              <Table
                columns={fetchDataColumns}
                dataSource={fetchRationalDataSource}
                title={() => <Title level={3}>Rational</Title>}
                pagination={false}
                rowKey="name"
              />{" "}
            </Card>
            <Card>
              <Table
                columns={fetchDataColumns}
                dataSource={fetchNewsDataSource}
                title={() => <Title level={3}>News Data</Title>}
                pagination={false}
                rowKey="name"
              />
            </Card>
          </div>
        </div>
        <Divider />
        <div className="d-flex justify-content-center">
          <Card>
            <Table
              columns={fetchDataColumns}
              dataSource={nseDataSource}
              title={() => <Title level={3}>NSE Data</Title>}
              pagination={false}
              rowKey="name"
            />
          </Card>
        </div>
        <div className="text-7xl">Migrate Data :</div>
        <div className="d-flex justify-content-center">
          <Card>
            <Table
              columns={transferDataColumns}
              dataSource={transferDataDataSource}
              title={() => <Title level={3}></Title>}
              pagination={false}
              rowKey="name"
            />
          </Card>{" "}
          <Card>
            <Table
              columns={scanDataColumns}
              dataSource={scanDataSource}
              title={() => <Title level={3}>SCAN Data</Title>}
              pagination={false}
              rowKey="name"
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Admin;
