import App from "../App";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div class="container text-start fs-5">
      <div class="p-4 bg-light fs-1">Contact Us</div>
      <div class="row">
        <div class="col-md-6">
          <div class="my-5 fs-3">
            Infodesk
            <div>
              <hr />
            </div>
          </div>
          <div>Toll Free(India): 1800 209 4545</div>
          <div>Available from: 8 am to 8 pm IST</div>
          <div class="mt-4">Toll Free(India): 1800 209 4545</div>
          <div>Available from: 8 am to 8 pm IST</div>

          <div class="mt-4">
            <span>Send us a mail:</span>
            <a class="tc-blue">infodesk@gmail.com </a>
          </div>
          <div>
            (Mails to this Email ID will be forwarded to the concerned
            department.
          </div>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
          <div class="my-5 fs-3">
            Office Networks
            <div>
              <hr />
            </div>
          </div>

          <div class="tc-blue">
            <a>
              Offices in India <i class="bi bi-arrow-right tc-blue fs-4"></i>
            </a>
          </div>
          <div class="tc-blue">
            <a>
              Global Presence <i class="bi bi-arrow-right fs-4"></i>{" "}
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="fs-3 my-5">
            Investor Enquiry
            <div>
              <hr />
            </div>
          </div>
          <div>Please contact</div>
          <div class="mt-4">
            Investor Services: 1-800-3094-001 (Toll Free No.)
          </div>
          <div>(Time: 10 am to 6.00 pm IST)</div>

          <div class="mt-4">
            <span>
              E-mail:
              <a class="tc-blue">
                igrc@gmail.com
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </span>
            <div class="tc-blue">
              <a>
                Visit our Investor Relations site
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="fs-3 my-5">
              Business Enquiry
              <div>
                <hr />
              </div>
            </div>
            <div class="tc-blue">
              <a>
                Lorem.
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="tc-blue">
              <a>
                Lorem, ipsum.
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="tc-blue">
              <a>
                Lorem, ipsum.
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="tc-blue">
              <a>
                Lorem, ipsum.lorem
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="my-5 fs-3">
              Career Enquiry
              <div>
                <hr />
              </div>
            </div>
            <div class="tc-blue">
              <a>
                Please visit our Careers site
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
            <div class="tc-blue">
              <a>
                Recruitment Caution
                <i class="bi bi-arrow-right fs-4 px-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
