import React, { useState } from "react";
import "../shared/staticStyles.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Sidebar";
import App from "../../App";

const topics = [
  {
    title: "Topic 1",
    subheaders: [
      "Debt/Equity",
      "Bond/NCD",
      "Coupon/Yield",
      "Yield/Effective Yield",
      "Investment Grade/Speculative Grade",
    ],
    content: [
      "Debt represents borrowed money that must be repaid over time, usually with interest. When a company issues debt, it essentially borrows money from investors or financial institutions. This borrowed money can be in the form of loans or bonds. The company is obligated to make periodic interest payments to the lenders and repay the principal amount at maturity.Example: A corporation issues bonds worth $1 million with a 5% annual interest rate. Investors who purchase these bonds are effectively lending $1 million to the company, which must pay them 5% interest annually until the bonds mature, at which point the principal amount is repaid.Equity: Equity refers to ownership in a company, represented by shares of stock. When individuals or entities purchase shares of a company's stock, they become shareholders, which entitles them to a portion of the company's profits and voting rights at shareholder meetings.Example: If a company has issued 1,000 shares of stock and an investor purchases 100 shares, they own 10% of the company. As a shareholder, they may receive dividends if the company distributes profits, and they have the right to vote on company decisions at annual meetings.",
      "A bond is a debt investment where an investor loans money to an entity, typically a corporation or government, for a defined period at a fixed interest rate. Bonds have a predetermined maturity date, at which point the issuer repays the bond's face value to the investor.Example: A government issues a 10-year bond with a face value of $1,000 and a 3% annual interest rate. Investors who purchase this bond will receive $30 in interest each year for 10 years, and at the end of the term, they will receive the $1,000 face value. NCD (Non-Convertible Debenture): An NCD is a type of bond that cannot be converted into equity shares of the issuing company. Unlike convertible bonds, NCDs offer a fixed interest rate and maturity date, providing investors with a steady income stream.Example: A corporation issues NCDs worth $500,000 with a 5-year term and a 6% annual interest rate. Investors who purchase these NCDs will receive 6% interest annually for 5 years, with the principal amount repaid at maturity.",
      "The coupon refers to the fixed interest rate paid on a bond's face value. It represents the periodic interest payments that bondholders receive from the issuer.Example: A bond with a face value of $1,000 and a 4% annual coupon rate will pay bondholders $40 in interest each year ($1,000 * 4%).Yield: Yield is the return on investment expressed as a percentage, taking into account the bond's interest payments and its current market price. It reflects the annual income generated by the bond relative to its current market value.Example: If the aforementioned $1,000 bond with a 4% coupon rate is currently trading at $1,050 in the market, its yield would be 3.81% ($40 / $1,050).",
      "Yield is the total return on investment from dividends or interest payments, usually expressed annually as a percentage of the investment's cost. It represents the income generated by the investment relative to its initial price.Example: An investor purchases a stock for $100 and receives $5 in dividends over the course of a year. The yield on the investment would be 5% ($5 / $100).Effective Yield: Effective yield is the yield of a bond calculated to reflect the effects of compounding interest. It takes into account any reinvestment of interest payments during the bond's term.Example: A bond with a 5% coupon rate pays interest semi-annually. If an investor reinvests these interest payments at a rate of 3% per period, the effective yield would be slightly higher than the stated coupon rate due to the compounding effect.",
      "Investment Grade: Investment-grade bonds are considered relatively low risk and are typically rated BBB- or higher by credit rating agencies. These bonds are issued by financially stable companies or governments with a strong ability to meet their debt obligations.Example: Bonds issued by established corporations with stable cash flows, such as blue-chip companies like Coca-Cola or Microsoft, are often rated investment grade by credit rating agencies.Speculative Grade: Speculative-grade bonds, also known as junk bonds, are bonds with a higher risk of default. They are typically rated below BBB- by credit rating agencies and offer higher yields to compensate investors for the increased risk.Example: Bonds issued by companies with lower credit ratings, volatile cash flows, or significant debt levels may be considered speculative grade. These companies may include startups, highly leveraged firms, or those operating in cyclical industries.",
    ],
  },
  {
    title: "Topic 1",
    subheaders: [
      "Credit Rating",
      "Payment Frequency",
      "Convertible/Non-convertible Bond",
      "Secured/Unsecured Bond",
      "Senior/Subordinated Bond",
    ],
    content: [
      "Credit rating is an assessment of the creditworthiness of a borrower, such as a corporation or government, conducted by credit rating agencies. Ratings range from AAA (highest) to D (default), with each rating indicating the likelihood of default on the borrower's debt obligations.Example: Standard & Poor's, Moody's, and Fitch are among the most widely recognized credit rating agencies. They assign credit ratings based on factors such as the borrower's financial strength, debt levels, cash flow stability, and economic outlook.",
      "Payment frequency refers to how often interest payments or dividends are made to bondholders or shareholders. The frequency of payments can vary depending on the terms of the bond or stock issuance and the preferences of the issuer.Example: Bonds may pay interest monthly, quarterly, semi-annually, or annually, depending on the terms specified in the bond indenture. Similarly, dividends on stocks may be paid quarterly, semi-annually, or annually, as determined by the company's board of directors.",
      "Convertible Bond: Convertible bonds allow bondholders to convert their bonds into a specified number of common stock shares of the issuing company. This provides investors with the opportunity to participate in the company's equity upside while still receiving fixed income payments.Example: A corporation issues convertible bonds with a conversion ratio of 10:1, meaning each bond can be converted into 10 shares of common stock. If the stock price increases substantially, bondholders may choose to convert their bonds into equity to benefit from the appreciation.Non-convertible Bond: Non-convertible bonds do not offer the option to convert into stock. Instead, they provide investors with fixed interest payments and return of principal at maturity, similar to traditional bonds.Example: Government bonds and corporate bonds that do not include conversion features are examples of non-convertible bonds. Investors receive periodic interest payments until the bond matures, at which point they are repaid the principal amount.",
      "Secured bonds are backed by specific assets of the issuer, providing bondholders with collateral in case of default. If the issuer fails to make interest or principal payments, bondholders have a claim on the designated assets to recover their investment.Example: Mortgage-backed securities are a type of secured bond where the underlying assets are residential or commercial mortgages. If borrowers default on their mortgage payments, bondholders have a claim on the underlying properties to recoup their investment.Unsecured Bond: Unsecured bonds, also known as debentures, are not backed by collateral and rely solely on the issuer's creditworthiness for repayment. In the event of default, bondholders do not have specific assets to seize but may pursue legal action against the issuer.Example: Corporate bonds issued by companies with strong credit ratings and stable financial positions are often unsecured. Investors rely on the issuer's ability to generate sufficient cash flow to meet their debt obligations.",
      "Senior Bond: Senior bonds have a higher claim on the issuer's assets and cash flows compared to other debt obligations. In the event of bankruptcy or liquidation, senior bondholders are paid before subordinated bondholders and other creditors.Example: Senior bonds issued by corporations typically have lower interest rates than subordinated bonds but offer greater security in terms of repayment priority. They are considered less risky investments due to their seniority in the capital structure.Subordinated Bond: Subordinated bonds are lower in priority compared to senior bonds and are repaid after senior bondholders in the event of default. They carry higher interest rates to compensate investors for the increased risk of lower recovery in bankruptcy proceedings.Example: Subordinated bonds, also known as junior or mezzanine debt, may be issued by companies with weaker credit profiles or higher leverage ratios. Investors in subordinated bonds accept a higher risk of default in exchange for the potential for higher returns.",
    ],
  },
  {
    title: "Topic 2",
    subheaders: [
      "Physical/Demat Mode",
      "Private Placement/Public Issue",
      "Tax-free/Taxable Bonds",
      "Listed/Unlisted Securities",
      "Basis of Allotment",
    ],
    content: [
      "Physical Mode: In the physical mode, securities such as stocks or bonds are represented by physical certificates issued by the company or its registrar. Investors hold these certificates as proof of ownership.Demat Mode: In the demat (dematerialized) mode, securities are held electronically in a dematerialized form within a demat account. Instead of physical certificates, ownership is recorded electronically by depository participants. Example: In physical mode, an investor might possess physical share certificates of a company. In demat mode, the same investor would hold shares electronically within their demat account.",
      "Private Placement: Private placement involves the sale of securities directly to a select group of investors, such as institutional investors or high-net-worth individuals, without making a public offering. It is typically used by companies to raise capital quickly and efficiently. Public Issue: Public issue, also known as an initial public offering (IPO), involves the sale of securities to the general public through a regulated stock exchange. Companies issue shares to the public for the first time, allowing individual investors to become shareholders. Example: A startup seeking funding might opt for private placement by selling shares to venture capitalists or angel investors. Conversely, a well-established company might choose a public issue by offering shares to retail investors through an IPO.",
      "Tax-free Bonds: Tax-free bonds are issued by governments or government-backed entities and offer interest income that is exempt from taxation at the federal or state level. They provide investors with a tax-efficient way to generate income. Taxable Bonds: Taxable bonds are bonds on which interest income is subject to taxation at the applicable federal, state, or local tax rates. Investors receive interest payments, but they must report and pay taxes on this income. Example: Municipal bonds issued by local governments are often tax-free, while corporate bonds and Treasury bonds are typically taxable. Investors in tax-free bonds benefit from higher after-tax returns compared to taxable bonds.",
      "Listed Securities: Listed securities are financial instruments, such as stocks or bonds, that are traded on a regulated stock exchange. They are subject to listing requirements and are publicly traded, allowing investors to buy and sell them on the exchange. Unlisted Securities: Unlisted securities are financial instruments that are not traded on a public stock exchange. They may be privately held and traded over-the-counter (OTC) or through private transactions. Example: Stocks of well-known companies like Apple or Google are listed securities traded on major stock exchanges such as the New York Stock Exchange (NYSE) or NASDAQ. Private equity investments or venture capital shares are examples of unlisted securities.",
      "Basis of allotment refers to the process of allocating securities, such as shares in an IPO or bonds in a debt offering, to investors who have applied for them. It outlines the criteria and methodology used to distribute securities among eligible applicants. Example: In an IPO, the basis of allotment determines how many shares each investor will receive based on factors such as the size of their investment, the total demand for shares, and any allocation preferences specified by the company.",
    ],
  },
  {
    title: "Topic 2",
    subheaders: [
      "Institutional/Non-institutional/Retail",
      "Yield Curve",
      "Downgrade",
      "Default",
      "Fixed/Floating Coupon",
    ],
    content: [
      "Institutional Investors: Institutional investors are large entities that invest on behalf of others, such as pension funds, mutual funds, hedge funds, or insurance companies. They typically trade in large volumes and have significant financial resources. Non-institutional Investors: Non-institutional investors, also known as individual or retail investors, are individuals or small entities that invest in financial markets using their own funds rather than on behalf of others. Example: Pension funds, sovereign wealth funds, and endowments are examples of institutional investors. Individual investors who buy and sell stocks through brokerage accounts are considered non-institutional investors.",
      "The yield curve is a graphical representation of the relationship between the yields (interest rates) and maturity dates of a series of bonds or securities issued by the same entity. It shows how yields vary across different maturities, providing insights into the market's expectations for future interest rates and economic conditions. Example: A normal yield curve slopes upward, indicating that longer-term bonds have higher yields than shorter-term bonds. An inverted yield curve, where short-term yields are higher than long-term yields, is often seen as a predictor of economic recession.",
      "Downgrade refers to the lowering of a borrower's credit rating by a credit rating agency. It signifies a deterioration in the borrower's financial health or creditworthiness, increasing the risk of default on their debt obligations. Example: If a company's earnings decline or its debt levels increase, credit rating agencies may downgrade its credit rating from investment grade to speculative grade, leading to higher borrowing costs and reduced access to capital.",
      "Default occurs when a borrower fails to meet its debt obligations, such as making interest or principal payments on time. It signifies a breach of the terms agreed upon in the loan or bond agreement and can have serious financial consequences for the borrower and its creditors. Example: A company that misses a scheduled interest payment on its bonds or fails to repay its outstanding loans according to the agreed-upon terms may be considered in default by its creditors.",
      "Fixed Coupon: Fixed-rate bonds have a predetermined interest rate that remains constant throughout the life of the bond. Bondholders receive fixed interest payments at regular intervals until maturity, providing certainty of income. Floating Coupon: Floating-rate bonds have variable interest rates that fluctuate periodically based on changes in a specified benchmark rate, such as LIBOR (London Interbank Offered Rate) or the prime rate. Interest payments adjust accordingly, offering protection against changes in market interest rates. Example: A fixed-rate bond might offer a 5% annual coupon rate, while a floating-rate bond might offer interest payments equal to LIBOR plus 2%. Investors in fixed-rate bonds receive predictable income, while investors in floating-rate bonds receive payments that adjust with changes in interest rates.",
    ],
  },
  {
    title: "Topic 3",
    subheaders: [
      "Zero Coupon",
      "Cumulative Coupon",
      "Capital Appreciation",
      "Redemption Premium",
      "Step-up Coupon",
    ],
    content: [
      "Zero coupon bonds are debt securities that do not make periodic interest payments like traditional bonds. Instead, they are issued at a discount to their face value and redeemed at face value at maturity, providing investors with a return in the form of capital appreciation. Example: An investor purchases a zero coupon bond with a face value of $1,000 at a discounted price of $800. At maturity, the bond will be redeemed for its full face value of $1,000, resulting in a $200 capital gain for the investor.",
      "Cumulative coupon bonds are bonds where any missed interest payments accumulate and must be paid in full before any further interest payments can be made. This feature provides bondholders with greater assurance of receiving their expected interest income. Example: If a company misses an interest payment on a cumulative coupon bond due to financial difficulties, it must pay both the missed interest and the current interest in the next payment period before distributing any interest to other bondholders.",
      "Capital appreciation refers to the increase in the value of an investment over time. It occurs when the market price of the investment rises above its purchase price, resulting in a profit for the investor if the investment is sold at the higher price. Example: An investor purchases shares of a company at $50 per share, and over time, due to strong financial performance or market demand, the share price increases to $75 per share. The $25 increase in share price represents capital appreciation for the investor.",
      "Redemption premium, also known as call premium, is an additional amount paid by the issuer when redeeming a bond before its maturity date. It compensates bondholders for the loss of future interest income and can vary depending on the terms of the bond agreement. Example: If a bond has a face value of $1,000 and a redemption premium of 3%, the issuer must pay bondholders $1,030 for each bond redeemed before maturity.",
      "Step-up coupon bonds are bonds with a provision that allows the interest rate to increase (step up) over time at predetermined intervals. This feature provides investors with higher coupon payments if market interest rates rise. Example: A step-up coupon bond might start with a 3% interest rate for the first five years, after which the interest rate increases to 5% for the remaining term of the bond.",
    ],
  },
  {
    title: "Topic 3",
    subheaders: [
      "Discount to Face Value",
      "Capital Gains Tax",
      "Interest Income",
      "Dividend",
      "Bullet/Staggered Redemption",
    ],
    content: [
      "A bond is said to be trading at a discount to face value when its market price is below its face value. This occurs when market interest rates are higher than the bond's coupon rate or when the issuer's creditworthiness is perceived to be lower. Example: A bond with a face value of $1,000 and a market price of $950 is trading at a discount to face value. Investors can purchase the bond at $950 and receive the full $1,000 face value at maturity, resulting in a capital gain.",
      "Capital gains tax is a tax levied on the profit earned from the sale of capital assets, such as stocks, bonds, or real estate. The tax rate depends on factors such as the holding period of the asset and the investor's tax bracket. Example: If an investor sells shares of stock for $5,000 that were purchased for $4,000, the $1,000 profit is subject to capital gains tax. The tax rate may vary depending on whether the shares were held for the short term (less than one year) or long term (more than one year).",
      "Interest income refers to the money earned from investments that pay interest, such as bonds, savings accounts, or certificates of deposit (CDs). It is a form of passive income generated by lending money to borrowers in exchange for periodic interest payments. Example: An investor who owns bonds receives interest income from the bond issuer in the form of regular interest payments, typically semi-annually or annually, based on the bond's coupon rate.",
      "Dividends are distributions of a portion of a company's profits to its shareholders. They are typically paid in cash but can also be issued as additional shares of stock. Dividends provide investors with a source of income and are often a key factor in stock investment decisions. Example: A publicly traded company might declare a dividend of $0.50 per share to be paid to its shareholders. If an investor owns 100 shares of the company's stock, they would receive $50 in dividend income.",
      "Bullet Redemption: Bullet redemption refers to the repayment of the entire principal amount of a bond or loan in a single payment at maturity. It is a common redemption structure for bonds where the issuer makes one lump-sum payment to bondholders. Staggered Redemption: Staggered redemption, also known as serial redemption, involves the repayment of portions of the principal amount of a bond or loan in multiple installments over time. It allows issuers to spread out their debt repayment obligations. Example: A bond with bullet redemption will repay the full face value of the bond to investors at its maturity date. In contrast, a bond with staggered redemption may repay a portion of the principal each year until the bond is fully redeemed.",
    ],
  },
];

const MustKnow = () => {
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [selectedSubheaderIndex, setSelectedSubheaderIndex] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleNextContent = () => {
    if (
      selectedSubheaderIndex <
      topics[selectedTopicIndex].content.length - 1
    ) {
      setSelectedSubheaderIndex(selectedSubheaderIndex + 1);
    }
  };

  const handlePreviousContent = () => {
    if (selectedSubheaderIndex > 0) {
      setSelectedSubheaderIndex(selectedSubheaderIndex - 1);
    }
  };

  const handleCloseSidebar = () => {
    setSelectedTopicIndex(null);
    setSelectedSubheaderIndex(null);
    setSidebarOpen(false);
  };

  const filteredTopics = topics.filter(
    (topic) =>
      topic.subheaders.some((subheader) =>
        subheader.toLowerCase().includes(searchQuery.toLowerCase())
      ) ||
      topic.content.some((content) =>
        content.toLowerCase().includes(searchQuery.toLowerCase())
      )
  );

  return (
    <div className="Start">
      {/* <Sidebar /> */}
      <div className="main-content">
        <div>
          <div className="tabs">
            <button>Visit Website</button>
            <button>Blog</button>
            <button>Contacts</button>
          </div>
          <hr />
          <h4 style={{ fontWeight: 200 }}>
            Home / Knowledge Base / Domain Concepts / Must Know
          </h4>
          <h1>Must Know Category</h1>
          <p style={{ marginTop: "1rem" }}>
            The Must Know Category represents an indispensable reservoir of
            crucial insights and foundational knowledge that are absolutely
            imperative for grasping, comprehending, and mastering the
            fundamental principles, essential concepts, and intricate
            intricacies within our domain.
          </p>
        </div>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>

        <div className="small-card-container">
          {filteredTopics.map((topic, index) => (
            <div key={index} className="small-card">
              <p className="small-card-title">{topic.title}</p>
              {topic.subheaders.map((subheader, idx) => (
                <p
                  key={idx}
                  className="new-content"
                  onClick={() => {
                    setSelectedTopicIndex(index);
                    setSelectedSubheaderIndex(idx);
                    setSidebarOpen(true);
                  }}
                >
                  {subheader}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="right-side">
        {sidebarOpen && (
          <div className="sidebar-panel">
            <div className="sidebar-panel-content">
              <span
                className="sidebar-panel-close"
                onClick={handleCloseSidebar}
              >
                &times;
              </span>
              <h2>
                {topics[selectedTopicIndex].subheaders[selectedSubheaderIndex]}
              </h2>
              <p>
                {topics[selectedTopicIndex].content[selectedSubheaderIndex]}
              </p>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <button onClick={handlePreviousContent}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button onClick={handleNextContent}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MustKnow;
