import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./bot1.css"; // Import the CSS file for styling

const PDF = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // Function to save the token in local storage
  const saveTokenToLocalStorage = (token) => {
    localStorage.setItem("token", token);
    setToken(token); // Optionally, you can also update state with the token
  };

  // Fetch announcement data
  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/nse/pdf-data`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response data to the console
        setAnnouncementData(data);
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, []);

  return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>Announcements</h1>
      </div>
      <table className="announcement-table">
        <thead>
          <tr>
            <th>ISIN</th>
            <th>Issuer</th>
            <th>Seniority</th>
            <th>Ratings</th>
            <th>Convertibility</th>
            <th>Registrars</th>
            <th>Redemption Date</th>
            <th>Coupon Rate</th>
            <th>Call Date</th>
            <th>Call Price</th>
          </tr>
        </thead>
        <tbody>
          {announcementData.map((announcement, index) => (
            <tr key={index}>
              <td>{announcement.isin}</td>
              <td>{announcement.issuer}</td>
              <td>{announcement.seniority}</td>
              <td>{announcement.ratings}</td>
              <td>{announcement.convertibility}</td>
              <td>{announcement.registrars}</td>
              <td>{announcement.redemptionDate}</td>
              <td>{announcement.couponRate}</td>
              <td>{announcement.callDate}</td>
              <td>{announcement.callPrice}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PDF;
