import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./bot1.css"; // Import the CSS file for styling

const CorporateBonds = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState("");
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Fetch announcement data
  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/corporatebonds`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response data to the console
        setAnnouncementData(data);
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, []);

  // Get unique issuer names
  const issuerNames = [...new Set(announcementData.map((a) => a.issuername))];

  // Filter data based on selected issuer
  const filteredData = selectedIssuer
    ? announcementData.filter((a) => a.issuername === selectedIssuer)
    : announcementData;

  return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>Corporate Bond – OTC Trades</h1>
        {/* <div>
          <label htmlFor="issuer-select">Filter by Issuer: </label>
          <select
            id="issuer-select"
            value={selectedIssuer}
            onChange={(e) => setSelectedIssuer(e.target.value)}
          >
            <option value="">All</option>
            {issuerNames.map((issuer, index) => (
              <option key={index} value={issuer}>
                {issuer}
              </option>
            ))}
          </select>
        </div> */}
      </div>
      <table className="announcement-table">
        <thead>
          <tr>
            <th>Security Code</th>
            <th>ISIN</th>
            <th>Issuer Name</th>
            <th>Coupon Rate</th>
            <th>Maturity Date</th>
            <th>LTP</th>
            <th>Weighted Average Price</th>
            <th>Yield</th>
            <th>Turnover</th>
            <th>Number of Trades</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((announcement, index) => (
            <tr key={index}>
              <td>{announcement.securityCode}</td>
              <td>{announcement.isinno}</td>
              <td>{announcement.issuername}</td>
              <td>{announcement.couponrate}</td>
              <td>{announcement.maturityDate}</td>
              <td>{announcement.ltp}</td>
              <td>{announcement.weightedAveragePrice}</td>
              <td>{announcement.yield}</td>
              <td>{announcement.turnover}</td>
              <td>{announcement.nooftrades}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CorporateBonds;
