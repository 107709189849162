import React, { useState } from 'react';
import { Typography, Table, Card, Button, Modal } from 'antd';
import './modal.css';

const { Title } = Typography;

const mainColumns = (handleRowClick) => [
  { title: 'Instrument Features', dataIndex: 'feature', key: 'feature' },
  { title: 'Weightage', dataIndex: 'weightage', key: 'weightage' },
  { title: 'Max Points', dataIndex: 'maxPoints', key: 'maxPoints' },
  { title: 'Description', dataIndex: 'description', key: 'description' },
  { title: 'Source', dataIndex: 'source', key: 'source' },
];

const mainData = [
  { key: '1', feature: 'Type', weightage: '25%', maxPoints: '40', description: '1. Senior/Subordinated/Hybrid', source: 'Term sheet' },
  { key: '2', feature: 'Security', weightage: '20%', maxPoints: '25', description: '1. Exclusive or First Pari Passu/Residual/Unsecured', source: 'Term sheet' },
  { key: '3', feature: 'Options', weightage: '10%', maxPoints: '10', description: '1. Put/Call', source: 'Term sheet' },
  { key: '4', feature: 'Coupon payment', weightage: '10%', maxPoints: '10', description: '1. Uniform/Step-up/Zero coupon', source: 'Term sheet' },
  { key: '5', feature: 'Redemption', weightage: '10%', maxPoints: '15', description: '1. Redeemable/Non-redeemable(Perpetual) 2. Vanilla/Hybrid', source: 'Term sheet' },
  { key: '6', feature: '', weightage: '75%', maxPoints: '100', description: '5', source: '' },
];

const subTableColumns = (title) => [
  { title, dataIndex: 'item', key: 'item' },
  { title: 'Points', dataIndex: 'points', key: 'points' },
];

const subTableData = {
  'Type': [
    { key: '1', item: 'Senior', points: '20' },
    { key: '2', item: 'Subordinated', points: '10' },
    { key: '3', item: 'Hybrid', points: '0' },
  ],
  'Security': [
    { key: '1', item: 'Exclusive', points: '10' },
    { key: '2', item: 'First Pari Passu', points: '8' },
    { key: '3', item: 'Residual', points: '6' },
    { key: '4', item: 'Unsecured', points: '0' },
  ],
  'Options': [
    { key: '1', item: 'No options', points: '10' },
    { key: '2', item: 'Put & Call options', points: '6' },
    { key: '3', item: 'Other options', points: '0' },
  ],
  'Coupon payment': [
    { key: '1', item: 'Uniform', points: '10' },
    { key: '2', item: 'Step up', points: '8' },
    { key: '3', item: 'Zero coupon/Cumulative', points: '6' },
    { key: '4', item: 'Discretionary', points: '0' },
  ],
  'Redemption': [
    { key: '1', item: 'Redeemable/Vanilla', points: '15' },
    { key: '2', item: 'Redeemable/Hybrid', points: '10' },
    { key: '3', item: 'Non-redeemable', points: '0' },
  ],
};

const Complexity = () => {
  const [visible, setVisible] = useState(null);

  const handleRowClick = (record) => {
    setVisible(record.feature);
  };

  const handleCloseModal = () => {
    setVisible(null);
  };

  return (
    <div className="complexity-container">
      <Title level={4} className="section-title">
        Complexity Assessment
      </Title>
      
      <Card className="main-table-card">
        <Table
          columns={mainColumns(handleRowClick)}
          dataSource={mainData}
          pagination={false}
          bordered
          className="main-table"
          rowKey="key"
          onRow={(record) => ({
            onClick: () => handleRowClick(record), 
          })}
        />
      </Card>

      {Object.keys(subTableData).map((key) => (
        <Modal
          key={key}
          title={key}
          visible={visible === key}
          onCancel={handleCloseModal}
          footer={[
            <Button key="back" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleCloseModal}>
              OK
            </Button>,
          ]}
          width={500} 
          style={{ top: 20 }}
        >
          <Table
            columns={subTableColumns(key)}
            dataSource={subTableData[key]}
            pagination={false}
            bordered
            className="sub-table"
          />
        </Modal>
      ))}
    </div>
  );
};

export default Complexity;
