import { useParams } from "react-router-dom";
import BlogCard from "../Blogs/BlogCard";
import { useNavigate } from "react-router-dom";

const LeaderDetail = () => {
  const navigate = useNavigate();

  const styles = {
    card: {
      borderRadius: 20,
    },
    dim: {
      width: "320px",
      height: "400px",
    },
    management: {
      width: "250px",
      height: "300px",
    },
  };
  const { id } = useParams();
  console.log(id);
  return (
    <div>
      <div className=" text-center mt-7">
        <div className="row mb-5 ">
          <div className="col-md-6 col-sm-12 text-center">
            <img
              className="rounded"
              style={styles.dim}
              src="https://i.pravatar.cc/300?img=56"
              alt=""
            />
          </div>
          <div className="col-md-6 leader-name">
            <div>
              <div className="fs-1 tc-blue fw-bold">John Doe</div>
              <div className="fs-3">
                Co-Founder,
                <span className="tc-blue">CEO</span>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded shadow-lg row p-4">
          <div className="col-md-7 col-sm-12">
            <div className="text-start fs-2 tc-blue fw-bold">
              About John Doe
            </div>
            <p className="text-start fs-5">
              Our Founder and his data Ipsum is Test dummy text of the printing
              and typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown printer
              took a galley of type and scrambled it to make a type specimen
              book. Our Founder and his data Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book.
            </p>
            <div className="text-start tc-blue">
              <i className="bi bi-linkedin fs-2 mx-2"></i>
              <i className="bi bi-envelope-at fs-2 mx-2"></i>
            </div>
          </div>
          <div className="col-md-5 text-center">
            <div className="rounded shadow border border-primary-subtle p-4">
              <div className="tc-blue fs-3 fw-bold text-start my-2">
                John in the Media
              </div>
              <ul className="fs-4 text-start">
                <li className="fs-5">
                  One size fits all approach does not work for debt funds -
                  Times Of India
                </li>
                <li className="fs-5">Bonds are the future - Economic Times</li>
                <li className="fs-5">Bonds are the future - Economic Times</li>
                <li className="fs-5">Bonds are the future - Economic Times</li>
                <li className="fs-5">Bonds are the future - Economic Times</li>
                <li className="fs-5">Bonds are the future - Economic Times</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="my-5">
          <div className="tc-blue my-2 fw-bold fs-2">
            Here’s what I have to say
          </div>
          <div className="text-secondary fs-5">
            A sneak peak behind the curtains...
          </div>
        </div>
        <div>
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/7e90gBu4pas"
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>

          <div className="tc-blue my-5 fw-bold fs-3">@JohnDoes's Blog Wall</div>

          <div className="d-flex blog-card-list">
            <BlogCard></BlogCard>
            <BlogCard></BlogCard>
          </div>
          <div className="my-5 fs-3">
            <span>Want to see more? Head on over to our </span>
            <span className="tc-blue">Blogs</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderDetail;
