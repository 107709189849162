import { useLocation,Link } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import {
  Avatar,
  Button,
  Col,
  Icon,
  Input,
  List,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import axios from 'axios';
const { Title } = Typography;

// const Rational = () => {
//   const location = useLocation();
//   const newsData = location.state?.data;
//   console.log("In Rational NEWSDATA 1"+ newsData.data[0]);
//   console.log("In Rational NEWSDATA 2"+ newsData.data[1]);
//   console.log("In Rational NEWSDATA 3"+ newsData.data[2]);

//   return(
//     <>
//    <div style={{ display: 'flex' }}>
//         <Link to="/rating"><Button style={{ marginRight: "10px", marginTop: "5px" }} type="primary" shape="circle" icon="left">
//         </Button>
//         </Link>
//         <Title level={2}>Rational
//         </Title>
//       </div>

//      <Row>
//      <Col span={3}>
//       </Col>
//       <Col span={18}>
//         <Title>{newsData && newsData.name?newsData.name:'--'}</Title>
//         <strong style={{color:"blue"}} >{newsData && newsData.timestamp ? newsData.timestamp : "--"}</strong>
//         <p style={{ whiteSpace: 'pre-line',marginTop:'5px' }}>
//   { newsData && newsData.data && newsData.data.length ? newsData.data : null }
//   {/* // newsData.data.replace(/\n/g, '\n\n') : '--' */}
// </p>

//       </Col>
//       <Col span={3}>
//       </Col>

//      </Row>
//     </>
//   )


// }
// export default Rational

// const Rational = () => {
//   const location = useLocation();
//   const newsData = location.state?.data;
//   console.log("In Rational NEWSDATA 1"+ newsData.data[0]);
//   console.log("In Rational NEWSDATA 2"+ newsData.data[1]);
//   console.log("In Rational NEWSDATA 3"+ newsData.data[2]);

//   return(
//     <>
//       <div style={{ display: 'flex' }}>
//         <Link to="/rating"><Button style={{ marginRight: "10px", marginTop: "5px" }} type="primary" shape="circle" icon="left">
//         </Button>
//         </Link>
//         <Title level={2}>Rational</Title>
//       </div>

//       <Row>
//         <Col span={3}></Col>
//         <Col span={18}>
//           <Title>{newsData && newsData.name ? newsData.name : '--'}</Title>
//           <strong style={{color:"blue"}} >{newsData && newsData.timestamp ? newsData.timestamp : "--"}</strong>
//           <div>
//             {newsData && newsData.data && newsData.data.map((item, index) => (
//               <p key={index} style={{ whiteSpace: 'pre-line', marginTop:'5px' }}>{item}</p>
//             ))}
//           </div>
//         </Col>
//         <Col span={3}></Col>
//       </Row>
//     </>
//   );
// }

// export default Rational;



const Rational = () => {
  const location = useLocation();
  const newsData = location.state?.data;
  const type = location.state?.type;
  console.log("TYPE = ",type);
  // console.log("In Rational NEWSDATA 2"+ newsData.data[1]);
  // console.log("In Rational NEWSDATA 3"+ newsData.data[2]);

  return (
    <div style={{ backgroundColor: 'white' }}> 
      <div style={{ display: 'flex' }}>
        <Link to="/rating">
          <Button style={{ marginRight: "10px", marginTop: "5px" }} type="primary" shape="circle" icon="left" />
        </Link>
        <Title level={2}>Rational</Title>
      </div>

      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          {/* <Title>{newsData && newsData.name ? newsData.name : '--'}</Title> */}
          <Title>{newsData.name}</Title>
          <strong style={{ color: "blue" }}>{newsData && newsData.timestamp ? newsData.timestamp : "--"}</strong>

          <div>
            {type == "india" ? (
                newsData.data.map((item, index) => (
               <p key={index} style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>{item}</p>
                ))
              ) : (
            <p style={{ whiteSpace: 'pre-line', marginTop: '5px' }}>
            {newsData && newsData.data && newsData.data ? newsData.data.replace(/\n/g, '\n\n') : '--'}
            </p>
               )} 
         </div>

        </Col>
        <Col span={3}></Col>
      </Row>
    </div>
  );
}

export default Rational;
