import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./bot1.css"; // Import the CSS file for styling

const Bot1 = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // Function to save the token in local storage
  const saveTokenToLocalStorage = (token) => {
    localStorage.setItem("token", token);
    setToken(token); // Optionally, you can also update state with the token
  };

  // useEffect to save the token when fetched

  // Fetch announcement data
  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${baseUrl}/nse-announcement`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"), // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response data to the console
        setAnnouncementData(data);
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, []);

  return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>Announcements</h1>
      </div>
      <table className="announcement-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Subject</th>
            <th>Details</th>
            <th>Date</th>
            <th>Dissemination</th>
            <th>Difference</th>
            <th>Attachment</th>
          </tr>
        </thead>
        <tbody>
          {announcementData.map((announcement, index) => (
            <tr key={index}>
              <td>{announcement.name}</td>
              <td>{announcement.subject}</td>
              <td>{announcement.details}</td>
              <td>{announcement.date}</td>
              <td>{announcement.dissemination}</td>
              <td>{announcement.difference}</td>
              <td>
                <a
                  href={announcement.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bot1;
