import { useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Button } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import "./App.css";
import logo from "../src/assets/logo.png";
import logoShort from "../src/assets/logoShort.png";
import LoginRouter from "./Router/loginRouter";
import "primeflex/primeflex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  QuestionCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import ChatBot from "./components/bondGPT/ChatBot";
const { Header, Sider, Footer, Content } = Layout;

const App = (props) => {
  const history = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const [activemenuKey, setActiveMenuKey] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const [token, setToken] = useState(null);
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleSignOut = () => {
    localStorage.clear();
    setToken(null);
    history("/login");
  };

  const handleMenuClick = (e) => {
    console.log("click ", e);
    console.log("key", e.keyPath[1]);
  };

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && !this.rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("authToken");
    if (!savedData) {
      const razorToken = localStorage.getItem("razorpayAuthToken");
      if (razorToken) {
        history("/d/orders");
      } else handleSignOut();
    }
  }, []);

  return (
    <>
      {token !== null ? (
        <LoginRouter />
      ) : (
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <Link to="/d/dash">
              <div
                className="logo"
                style={{
                  height: "37px",
                  margin: "16px",
                }}
              >
                <img
                  src={collapsed ? logoShort : logo}
                  style={{ width: "100%", height: "50px" }}
                  alt="Logo"
                />
              </div>
            </Link>
            <Menu
              style={{ background: "#111111" }}
              theme="dark"
              mode="inline"
              selectedKeys={[activemenuKey]}
              onClick={handleMenuClick}
              onOpenChange={handleOpenChange}
              openKeys={openKeys}
            >
              <Menu.Item key="1">
                <Link to="/d/dash">
                  <DashboardOutlined />
                  <span className="nav-text">Dashboard</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="5">
                <Link to="/d/knowledge">
                  <DashboardOutlined />
                  <span className="nav-text">Knowledge Hub</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="7">
                <Link to="/d/values">
                  <QuestionCircleOutlined />
                  <span className="nav-text">Values</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="7">
                <Link to="/d/leaders">
                  <QuestionCircleOutlined />
                  <span className="nav-text">Leadership</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/d/blogs">
                  <QuestionCircleOutlined />
                  <span className="nav-text">Blogs</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/d/testimonials">
                  <QuestionCircleOutlined />
                  <span className="nav-text">Testimonials</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/d/contact">
                  <SettingOutlined />
                  <span className="nav-text">Contact Us</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to="/admin">
                  <SettingOutlined />
                  <span className="nav-text">Admin Panel</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/d/plans">
                  <SettingOutlined />
                  <span className="nav-text">Subscription</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="10">
                <Link to="/d/orders">
                  <SettingOutlined />
                  <span className="nav-text">Orders</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link to="/d/payments">
                  <SettingOutlined />
                  <span className="nav-text">Payments</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="15">
                <Link to="/d/kyc">
                  <SettingOutlined />
                  <span className="nav-text">KYC</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="8" onClick={handleSignOut}>
                <LogoutOutlined />
                <span className="nav-text">Logout</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ height: "100vh" }}>
            <Header style={{ background: "#fff", padding: 0 }}>
              <Row style={{ margin: "10px" }}>
                <Button
                  style={{ margin: "5px" }}
                  type="primary"
                  shape="round"
                  title="Back "
                  icon={<LeftOutlined />}
                  onClick={() => history(-1)}
                >
                  Go Back
                </Button>
                <Col span={20}>
                  <h2 style={{ marginLeft: "20px" }}>{props.header}</h2>
                </Col>
                <Col>
                  <div
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                    }}
                    onClick={handleSignOut}
                  ></div>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                margin: "10px 10px 0",
                padding: 10,
                background: "#fff",
                overflow: "auto",
              }}
            >
              <div style={{ padding: 10, background: "#fff", minHeight: 500 }}>
                <ChatBot></ChatBot>
                <Outlet></Outlet>
                {/* <Button
                  type="primary"
                  onClick={showDrawer}
                  className="open-button"
                  icon={<WechatOutlined />}
                ></Button> */}
              </div>
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default App;
