import './ISIN.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";


function Isin() {
  const [bondData, setBondData] = useState([]);
  const location = useLocation();
  const receivedData = location.state?.data;
  console.log("receivedData", receivedData);
  const [name, setName] = useState(
    receivedData && receivedData.name ? receivedData.name : ""
  );
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiTmljb2xlIEZhbGNhbyIsInJvbGxubyI6Miwic3ViIjoiY3JjZS45NjAyLmNlQGdtYWlsLmNvbSIsImlhdCI6MTcxMzc5NjM3MSwiZXhwIjoxNzEzODgyNzcxfQ.h6m5OfKRht7x6Hd308haR25bTkGjwK_8xJd_5F2UBxY";
        
        const headers = {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        
        const baseUrl ="http://localhost:8080";
        const response = await axios.get(`${baseUrl}/bond/${name}`, { headers });

        console.log(response.data);
        // Set bond data to the response data
        setBondData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <button className="green-button">ISIN</button>
          <button className="green-button2">Rating</button>
        </div>
        <div className="main">
          <div className="search-bar">
            <input type="text" placeholder="Search..." style={{ width: 'calc(100% - 370px)' }} />
            <select style={{ width: '130px' }}>
              <option>Filter</option>
            </select>
            <button className="green-button1" style={{ width: '130px' }}>
              Print
            </button>
          </div>
          <div id="details">
            BOND DETAILS FOR RELIANCE INDUSTRIES LTD
          </div>
          <table>
            <tbody>
              {/* Map over bondData and render each bond */}
              {bondData.map((bond, index) => (
                bond.data.map((bondDetail, idx) => (
                  <tr key={index + '-' + idx}>
                    <td className="table-title">ISIN</td>
                    <td>{bondDetail.isin}</td>
                    <td className="table-title">Security Description</td>
                    <td>{bondDetail.securitydescription}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>
        </div>
      </header>
    </div>
  );
}

export default Isin;
