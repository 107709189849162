// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table .ant-table-thead > tr > th {
  background-color: #f0f2f5; /* Light gray background */
  color: #000; /* Black text color */
  font-weight: bold;
  text-align: center; /* Center-align header text */
}

.custom-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #fafafa; /* Alternating row background */
}

.custom-table .ant-table-tbody > tr:hover {
  background-color: #e6f7ff; /* Light blue hover effect */
}

.custom-table .ant-table-cell {
  padding: 12px; /* Add padding to cells */
}

/* modal.css */

.financial-strength-container {
  padding: 20px;
}

.main-table-card {
  margin-bottom: 20px;
}

.main-table {
  background-color: #ffffff;
}

.custom-modal .ant-modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal .ant-modal-header {
  background-color: #f0f2f5;
  border-bottom: 1px solid #d9d9d9;
}

.custom-modal .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
}

.sub-table {
  margin-top: 10px;
}

.modal-button {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.modal-button:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Modal/modal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,0BAA0B;EACrD,WAAW,EAAE,qBAAqB;EAClC,iBAAiB;EACjB,kBAAkB,EAAE,6BAA6B;AACnD;;AAEA;EACE,yBAAyB,EAAE,+BAA+B;AAC5D;;AAEA;EACE,yBAAyB,EAAE,4BAA4B;AACzD;;AAEA;EACE,aAAa,EAAE,yBAAyB;AAC1C;;AAEA,cAAc;;AAEd;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".custom-table .ant-table-thead > tr > th {\n  background-color: #f0f2f5; /* Light gray background */\n  color: #000; /* Black text color */\n  font-weight: bold;\n  text-align: center; /* Center-align header text */\n}\n\n.custom-table .ant-table-tbody > tr:nth-child(even) {\n  background-color: #fafafa; /* Alternating row background */\n}\n\n.custom-table .ant-table-tbody > tr:hover {\n  background-color: #e6f7ff; /* Light blue hover effect */\n}\n\n.custom-table .ant-table-cell {\n  padding: 12px; /* Add padding to cells */\n}\n\n/* modal.css */\n\n.financial-strength-container {\n  padding: 20px;\n}\n\n.main-table-card {\n  margin-bottom: 20px;\n}\n\n.main-table {\n  background-color: #ffffff;\n}\n\n.custom-modal .ant-modal-content {\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.custom-modal .ant-modal-header {\n  background-color: #f0f2f5;\n  border-bottom: 1px solid #d9d9d9;\n}\n\n.custom-modal .ant-modal-title {\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.sub-table {\n  margin-top: 10px;\n}\n\n.modal-button {\n  border-radius: 5px;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.modal-button:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
