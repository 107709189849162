import React, { useState } from "react";
import "../shared/staticStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import App from "../../App";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="Start">
      {/* <Sidebar /> */}
      <div className="main-content">
        <div>
          {/* <div className="tabs">
          <button>Visit Website</button>
          <button>Blog</button>
          <button>Contacts</button>
        </div> */}
          <hr />
          <h4 style={{ fontWeight: 200 }}>Home / Knowledge Base</h4>
          <h1>Welcome</h1>
          <p style={{ marginTop: "1rem" }}>
            At SimplyBond, we're dedicated to providing you with valuable
            insights and resources to navigate the complexities of our industry.
            Whether you're a newcomer seeking foundational knowledge or an
            industry veteran looking to stay updated, our Knowledge Base offers
            a wealth of information tailored to your needs. Dive into our
            diverse range of topics and discover the latest trends, insights,
            and developments shaping our industry. From essential concepts to
            advanced strategies, SimplyBond is your go-to destination for
            comprehensive industry knowledge.
          </p>
        </div>

        <div className="small-card-container">
          <div className="small-card">
            <div
              onClick={() => navigate("domain")}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p className="small-card-title">Domain Concepts</p>
              <p className="small-description">
                From foundational theories to advanced topics, explore the core
                concepts that drive our industry forward.
              </p>
              <p className="small-article-count">
                6 Articles
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="small-arrow-icon"
                />
              </p>
            </div>
          </div>
          <div className="small-card">
            <p className="small-card-title">Market Infrastructure</p>
            <p className="small-description">
              Explore the intricate network of systems and mechanisms that form
              the backbone of our market infrastructure.
            </p>
            <p className="small-article-count">
              6 Articles
              <FontAwesomeIcon
                icon={faArrowRight}
                className="small-arrow-icon"
              />
            </p>
          </div>
          <div className="small-card">
            <p className="small-card-title">News Room</p>
            <p className="small-description">
              Stay informed with the latest news, updates, and developments
              shaping our industry landscape.
            </p>
            <p className="small-article-count">
              6 Articles
              <FontAwesomeIcon
                icon={faArrowRight}
                className="small-arrow-icon"
              />
            </p>
          </div>
          <div className="small-card">
            <p className="small-card-title">Miscellaneous</p>
            <p className="small-description">
              From tips and tricks to intriguing discoveries, our Miscellaneous
              section offers a wealth of knowledge to satisfy your curiosity.
            </p>
            <p className="small-article-count">
              6 Articles
              <FontAwesomeIcon
                icon={faArrowRight}
                className="small-arrow-icon"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="search-bar">
          <input type="text" placeholder="Search..." className="search-input" />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      </div>
    </div>
  );
};
export default Home;
