import React, { useState } from 'react';
import { Typography, Table, Card, Button, Modal } from 'antd';
import './modal.css';

const { Title } = Typography;

const mainColumns = (handleOpenModal) => [
  { title: 'Parameter', dataIndex: 'parameter', key: 'parameter' },
  { title: 'Weightage', dataIndex: 'weightage', key: 'weightage' },
  { title: 'Max Points', dataIndex: 'maxPoints', key: 'maxPoints' },
  { title: 'Source of information', dataIndex: 'source', key: 'source' },
  { title: 'Action', key: 'action', render: (_, record) => (
    <Button type="link" onClick={() => handleOpenModal(record.details)}>
      View Details
    </Button>
  )},
];

const mainData = [
  { key: '1', parameter: 'Credit Rating', weightage: '70%', maxPoints: '40', source: 'Term Sheet', details: 'Credit Rating' },
  { key: '2', parameter: 'No of Ratings', weightage: '5%', maxPoints: '10', source: 'Term Sheet', details: 'Number of Ratings' },
  { key: '3', parameter: 'Divergence in Ratings', weightage: '10%', maxPoints: '20', source: 'Rating Agencies & Term Sheet', details: 'Divergence in ratings' },
  { key: '4', parameter: 'Servicing Mechanism', weightage: '5%', maxPoints: '10', source: 'Term Sheet', details: 'Servicing Mechanism' },
  { key: '5', parameter: 'Group financial strength', weightage: '10%', maxPoints: '20', source: 'Term Sheet', details: 'Group Financial Strength' },
  { key: '6', parameter: '', weightage: '100%', maxPoints: '100', source: '' },
];

const subTableColumns = (title) => [
  { title, dataIndex: 'rating', key: 'rating' },
  { title: 'Points', dataIndex: 'points', key: 'points' },
];

// Define data for each sub-table
const subTableData = {
  'Credit Rating': [
    { key: '1', rating: 'AAA', points: '20' },
    { key: '2', rating: 'AAA(SO)', points: '19' },
    { key: '3', rating: 'AA+', points: '18' },
    // ... more rows
  ],
  'Number of Ratings': [
    { key: '1', rating: '2 and above', points: '20' },
    { key: '2', rating: 'Single rating', points: '10' },
    { key: '3', rating: 'No rating', points: '0' },
  ],
  'Divergence in ratings': [
    { key: '1', rating: 'At par', points: '20' },
    { key: '2', rating: '1 notch', points: '10' },
    { key: '3', rating: '2 notches and above', points: '0' },
  ],
  'Servicing Mechanism': [
    { key: '1', rating: 'Standalone', points: '20' },
    { key: '2', rating: 'Structured Payment Mechanism', points: '10' },
    { key: '3', rating: 'Fresh ratings', points: '0' },
  ],
  'Group Financial Strength': [
    { key: '1', rating: 'Explicit support', points: '20' },
    { key: '2', rating: 'Implicit Support', points: '10' },
    { key: '3', rating: 'No linkage', points: '0' },
  ],
};

const FinancialStrength = () => {
  const [visible, setVisible] = useState(null);

  const handleOpenModal = (title) => {
    setVisible(title);
  };

  const handleCloseModal = () => {
    setVisible(null);
  };

  return (
    <div className="financial-strength-container">
      <Title level={4} className="section-title">
        Financial Strength Assessment
      </Title>
      
      <Card className="main-table-card">
        <Table
          columns={mainColumns(handleOpenModal)}
          dataSource={mainData}
          pagination={false}
          bordered
          className="main-table"
          rowKey="key"
        />
      </Card>

      {Object.keys(subTableData).map((key) => (
        <Modal
          key={key}
          title={key}
          visible={visible === key}
          onCancel={handleCloseModal}
          footer={[
            <Button key="cancel" onClick={handleCloseModal} className="modal-button">
              Cancel
            </Button>,
            <Button key="ok" type="primary" onClick={handleCloseModal} className="modal-button">
              OK
            </Button>,
          ]}
          width={500}
          className="custom-modal"
        >
          <Table
            columns={subTableColumns(key)}
            dataSource={subTableData[key]}
            pagination={false}
            bordered
            className="sub-table"
          />
        </Modal>
      ))}
    </div>
  );
};

export default FinancialStrength;
