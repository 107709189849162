import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./DebtISINList.css"; // Import the CSS file for styling

const DebtISINListCompany = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedISIN, setSelectedISIN] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [responseData, setResponseData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation(); // Use the useLocation hook to get the location object
  const receivedData = location.state?.data;
  console.log(receivedData);
  const [name, setName] = useState(
    receivedData && receivedData.name ? receivedData.name : ""
  );
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // const baseUrl = "https://c94d-152-58-22-225.ngrok-free.app"; // Replace with your base URL

  useEffect(() => {
    fetchFilteredData(name, selectedISIN, selectedType);
  }, []);

  const fetchFilteredData = async (company, isin, type) => {
    try {
      let url = `${baseUrl}/nse/${company}`;
      console.log("ok");
      console.log(name);
      const response = await axios.get(url, {
        headers: {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")} `,

          //Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiQWRpdHlhIiwicm9sbG5vIjoxLCJzdWIiOiJhZGl0eWFzYWxndWRlMTExMkBnbWFpbC5jb20iLCJpYXQiOjE3MjA0MzMwMTAsImV4cCI6MTcyMDUxOTQxMH0.2qQKOwjdh7R7l-iniMwK6s62Qvlmzie-LnSu-6vNwh0`,
        },
      });

      let filteredData = response.data;

      if (isin) {
        filteredData = filteredData.filter((item) => item.isin === isin);
      }

      if (type) {
        filteredData = filteredData.filter((item) => item.type === type);
      }

      setResponseData(filteredData);
      console.log("Filtered Data:", filteredData); // Log the response data
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleISINChange = (event) => {
    const isin = event.target.value;
    setSelectedISIN(isin);
    fetchFilteredData(name, isin, selectedType);
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    fetchFilteredData(name, selectedISIN, type);
  };

  const handleISINClick = (isin) => {
    navigate(`/d/debt/info?isin=${isin}`);
  };

  const downloadFile = () => {
    const csvRows = [];

    // Add the headers
    const headers = ["ID", "Name", "ISIN", "Details", "Type", "URL"];
    csvRows.push(headers.join(","));

    // Add the data
    responseData.forEach((item) => {
      const row = [
        item.id,
        item.companyName,
        item.isin,
        item.details,
        item.type,
        item.document,
      ];
      csvRows.push(row.join(","));
    });

    // Create the CSV string
    const csvString = csvRows.join("\n");

    // Create a Blob object and a link element to trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "isin_data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // State variable to control visibility
  const showTypeSelect = responseData.length > 0;

  return (
    <div className="isin-container">
      <div className="isin-header">
        <h1>
          NSE Corporate Filings Offer Documents (Debt)
          <a
            href="https://www.nseindia.com/companies-listing/corporate-filings-offer-documents"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINK
          </a>
        </h1>
        {/* {showTypeSelect && (
          <div className="flex">
            <select
              className="clickable isin-filters"
              onChange={handleISINChange}
            >
              <option value="">Select ISIN</option>
              {[...new Set(responseData.map((item) => item.isin))].map(
                (isin, index) => (
                  <option key={index} value={isin}>
                    {isin}
                  </option>
                )
              )}
            </select>

            <select
              className="clickable isin-filters"
              onChange={handleTypeChange}
            >
              <option value="">Select Type</option>
              {[...new Set(responseData.map((item) => item.type))].map(
                (type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                )
              )}
            </select>
            <button className="button" onClick={downloadFile}>
              Download Csv
            </button>
          </div>
        )} */}
      </div>
      <div>
        <h2>Data Table</h2>
        <table className="isin-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>ISIN</th>
              <th>Details</th>
              <th>Type</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {responseData.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.companyName}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleISINClick(item.isin);
                    }}
                  >
                    {item.isin}
                  </a>
                </td>
                <td>{item.details}</td>
                <td>{item.type}</td>
                <td>
                  <a
                    href={item.document}
                    style={{ textDecoration: "none", borderRadius: "5px" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LINK
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DebtISINListCompany;
