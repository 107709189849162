import BlogImg from "../../assets/blog_img.svg";
import BlogAvatar from "../../assets/blog-avatar.svg";
import "./BlogCard.css";
import { Link } from "react-router-dom";
const BlogCard = () => {
  return (
    <div>
      <Link to="/blog-details/1">
        <div className="card cards text-start" style={{ maxWidth: 400 }}>
          <img className="w-100" src={BlogImg} alt="" />

          <div className="p-4 text-start w-100">
            <div className="w-100">
              <span className="badge text-bg-primary text-start my-2">
                3 day ago
              </span>
            </div>
            <div className="my-2 fw-bold fs-3">
              The best bonds to keep on your portfolio
            </div>
            <div className="d-flex text-start w-100 p-2">
              <img src={BlogAvatar} height="80" width="80" alt="" />
              <div className="ms-3">
                <div>First Last</div>
                <div>CEO</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
