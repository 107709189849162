import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import simplyBondLogo from '../assets/simplyBondlogo.png'

const Sidebar = () => {
  const [showSublist1, setShowSublist1] = useState(false);
  const [showSublist2, setShowSublist2] = useState(false);
  const [showSublist3, setShowSublist3] = useState(false);
  const [showSublist4, setShowSublist4] = useState(false);

  const toggleSublist1 = () => {
    setShowSublist1(!showSublist1);
  };

  const toggleSublist2 = () => {
    setShowSublist2(!showSublist2);
  };

  const toggleSublist3 = () => {
    setShowSublist3(!showSublist3);
  };

  const toggleSublist4 = () => {
    setShowSublist4(!showSublist4);
  };

  return (
    <div className="sidebar">
      <div className="nav-bar-title">
        <img src={simplyBondLogo} alt="SimplyBond Logo" />
      </div>
      <div className="nav-bar-sub-title">Welcome</div>
      <ul>
        <li onClick={toggleSublist1}>
          <span className="list-item-text">Domain Concepts</span>
          <FontAwesomeIcon icon={showSublist1 ? faAngleDown : faAngleRight} className="dropdown-arrow" />
        </li>
        {showSublist1 && (
          <ul>
            <li>Introduction</li>
            <li>Must Know Category</li>
            <li>Great To Know</li>
            <li>Good To Know </li>
          </ul>
        )}
        <li onClick={toggleSublist2}>
          <span className="list-item-text">Market Infrastructure</span>
          <FontAwesomeIcon icon={showSublist2 ? faAngleDown : faAngleRight} className="dropdown-arrow" />
        </li>
        {showSublist2 && (
          <ul>
            <li>Lorem Ipsum</li>
            <li>Lorem Ipsum</li>
            <li>Lorem Ipsum</li>
          </ul>
        )}
        <li onClick={toggleSublist3}>
          <span className="list-item-text">News Room</span>
          <FontAwesomeIcon icon={showSublist3 ? faAngleDown : faAngleRight} className="dropdown-arrow" />
        </li>
        {showSublist3 && (
          <ul>
            <li>Sublist Item 1</li>
            <li>Sublist Item 2</li>
            <li>Sublist Item 3</li>
          </ul>
        )}
        <li onClick={toggleSublist4}>
          <span className="list-item-text">Miscellaneous</span>
          <FontAwesomeIcon icon={showSublist4 ? faAngleDown : faAngleRight} className="dropdown-arrow" />
        </li>
        {showSublist4 && (
          <ul>
            <li>Sublist Item 1</li>
            <li>Sublist Item 2</li>
            <li>Sublist Item 3</li>
          </ul>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
