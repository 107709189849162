import { useEffect, useState } from "react";
import axios from "axios";
import { IoMdClose, IoMdSend, IoMdChatbubbles } from "react-icons/io";
import { UploadOutlined } from "@ant-design/icons";

import "./ChatBot.css";
import { Upload } from "antd";
import { Button, message } from "antd";

const ChatBot = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL1;
  useEffect(() => {
    const open = document.getElementById("open-button");
    const window = document.getElementById("chatbot-window");
    const close = document.getElementById("close-button");

    open.addEventListener("click", () => {
      window.classList.remove("display-none");
      open.classList.add("display-none");
    });

    close.addEventListener("click", () => {
      window.classList.add("display-none");
      open.classList.remove("display-none");
    });
  }, []);
  const [prompt, setPrompt] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();

    const sent = document.createElement("div");
    const reply = document.createElement("div");
    const chatWindow = document.getElementById("chat-window");

    const input = document.getElementById("user-input");

    chatWindow.appendChild(sent);
    sent.innerHTML = prompt;
    sent.classList.add("user-message");
    input.value = "";

    try {
      const response = await axios.post(`${baseUrl}/query`, {
        query: prompt,
      });

      const answer = response.data.response;
      reply.innerHTML = answer;
    } catch (err) {
      console.log(err);
    }
    chatWindow.appendChild(reply);
    reply.classList.add("bot-message");
  };
  const props = {
    name: "file",
    action:
      "http://ec2-65-0-120-178.ap-south-1.compute.amazonaws.com/process_pdf",
    beforeUpload: (file) => {
      // handleUpload(file);
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="chatbot1">
      <div className="chatbot-window display-none" id="chatbot-window">
        <div className="cb-heading">
          <span>Chatbot</span>
          <IoMdClose
            id="close-button"
            style={{ fontSize: "28px", cursor: "pointer" }}
            onClick={() => {
              const window = document.getElementById("chatbot-window");
              window.classList.add("display-none");
              const open = document.getElementById("open-button");
              open.classList.remove("display-none");
            }}
          />
        </div>
        <div className="chat-window" id="chat-window">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
        <div className="chat-input">
          <form autoComplete="false" onSubmit={submitHandle} id="chatbot-form">
            <input
              placeholder="enter query"
              id="user-input"
              type="text"
              value={prompt}
              onChange={(e) => {
                setPrompt(e.target.value);
              }}
            />
            <div className="icon-box" onClick={submitHandle}>
              <IoMdSend style={{ fontSize: "20px", color: "white" }} />
            </div>
          </form>
        </div>
      </div>
      <div className="button-wrapper" id="open-button">
        <div className="open-button">
          <IoMdChatbubbles style={{ fontSize: "30px" }} />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
