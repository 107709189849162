import { Layout, Menu, theme } from "antd";
import { Link, Outlet } from "react-router-dom";

import logo from "../../assets/logo.png";

const { Header, Content, Footer } = Layout;
const items = new Array(15).fill(null).map((_, index) => ({
  key: index + 1,
  label: `nav ${index + 1}`,
}));
const LandingPageLayout = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <div>
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link to="/home">
            <div className="logo">
              <img
                src={logo}
                style={{
                  width: "100%",
                  height: "55px",
                }}
                alt="Logo"
              />
            </div>{" "}
          </Link>

          <Menu
            theme="dark"
            mode="horizontal"
            style={{
              flex: 1,
              minWidth: 800,

              position: "fixed",
              right: "0",
            }}
            className="text-lg"
          >
            {" "}
            <Menu.Item key="1">
              <Link to="/knowledge">
                <span className="nav-text">Knowledge Hub</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/values">
                <span className="nav-text">Values</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/leaders">
                <span className="nav-text">Leadership</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/blogs">
                <span className="nav-text">Blogs</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/testimonials">
                <span className="nav-text">Testimonials</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/contact">
                <span className="nav-text">Contact Us</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/login">
                <span className="nav-text">Login</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content
          style={{
            padding: "0 48px",
          }}
        >
          <div
            style={{
              background: colorBgContainer,

              padding: 24,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
    </div>
  );
};
export default LandingPageLayout;
