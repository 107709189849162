import ReactFlipCard from "reactjs-flip-card";
import { Carousel, Divider } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./Leadership.css";
const contentStyle = {
  margin: 10,
  borderRadius: 20,
  height: "500px",
  color: "#fff",
  textAlign: "center",
  alignItems: "center",
  background: "#364d79",
};
const Leadership = () => {
  const navigate = useNavigate();
  const styles = {
    card: {
      borderRadius: 20,
    },
    dim: {
      width: "270px",
      height: "350px",
    },
    management: {
      width: "200px",
      height: "250px",
    },
  };
  return (
    <div class=" text-center">
      <div class="">
        <Divider className="tc-blue fs-1 fw-bold my-5">
          Meet the Founder
        </Divider>

        <div class="d-flex justify-content-evenly">
          <ReactFlipCard
            onClick={() => navigate("leader/1")}
            frontStyle={styles.card}
            backStyle={styles.card}
            containerStyle={styles.dim}
            frontComponent={
              <div>
                {" "}
                <img
                  class="rounded"
                  style={styles.dim}
                  src="https://i.pravatar.cc/300?img=56"
                  alt=""
                />
                <div style={{ fontSize: 30 }}>John Doe</div>
                <div style={{ fontSize: 20, color: "black" }}>
                  Co-Founder, CEO
                </div>
              </div>
            }
            backComponent={
              <div>
                <img
                  class="rounded"
                  style={styles.dim}
                  src="https://www.thisiscolossal.com/wp-content/uploads/2020/07/wray-1.jpg"
                  alt=""
                />
                <div style={{ fontSize: 30 }}>Johnny</div>
              </div>
            }
          />
          <Link to={"/leader/1"}>
            <ReactFlipCard
              frontStyle={styles.card}
              backStyle={styles.card}
              containerStyle={styles.dim}
              frontComponent={
                <div>
                  {" "}
                  <img
                    class="rounded"
                    style={styles.dim}
                    src="https://i.pravatar.cc/300?img=65"
                    alt=""
                  />
                  <div style={{ fontSize: 30 }}>James D</div>
                  <div style={{ fontSize: 20, color: "black" }}>
                    Co-Founder, CEO
                  </div>
                </div>
              }
              backComponent={
                <div>
                  <img
                    class="rounded"
                    style={styles.dim}
                    src="https://www.thisiscolossal.com/wp-content/uploads/2020/07/wray-1.jpg"
                    alt=""
                  />{" "}
                  <div style={{ fontSize: 30 }}>Jimmy</div>
                </div>
              }
            />{" "}
          </Link>{" "}
          <Link to={"/leader/1"}>
            <ReactFlipCard
              frontStyle={styles.card}
              backStyle={styles.card}
              containerStyle={styles.dim}
              frontComponent={
                <div>
                  {" "}
                  <img
                    class="rounded"
                    style={styles.dim}
                    src="https://i.pravatar.cc/300?img=63"
                    alt=""
                  />
                  <div style={{ fontSize: 30 }}>Aditya S</div>
                  <div style={{ fontSize: 20, color: "black" }}>
                    Co-Founder, CEO
                  </div>
                </div>
              }
              backComponent={
                <div>
                  <img
                    class="rounded"
                    style={styles.dim}
                    src="https://www.thisiscolossal.com/wp-content/uploads/2020/07/wray-1.jpg"
                    alt=""
                  />
                  <div style={{ fontSize: 30 }}>Adi</div>
                </div>
              }
            />
          </Link>
        </div>
      </div>

      <div class="pt-2  council-container mt-7 d-flex flex-column justify-content-evenly">
        <Divider className="tc-blue fs-1 fw-bold my-4">
          Governing Council
        </Divider>
        <Carousel arrows infinite={true} autoplay>
          <div>
            <div class="row" style={contentStyle}>
              <div class="col-md-5 col-sm-12 mb-4">
                <div>
                  <img
                    style={{ height: "400px", width: "400px" }}
                    class="rounded-circle m-4"
                    src="https://ca.slack-edge.com/T01HG2HNYRY-U01H19BMS95-9bd7b26445f4-512"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-7 text-start mb-4 council-leader-details">
                <div>
                  <div class="fs-3 fw-bold">
                    John <span class="tc-blue">Doe</span>
                  </div>
                  <p class="fs-5">
                    pellendus illum modi nulla laboriosam adipisci quaerat eos
                    maiores illum modi nulla laboriosam?
                  </p>
                  <button class="btn btn-primary w-50">Learn More</button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row" style={contentStyle}>
              <div class="col-md-5 col-sm-12 mb-4">
                <div>
                  <img
                    style={{ height: "400px", width: "400px" }}
                    class="rounded-circle m-4"
                    src="https://ca.slack-edge.com/T01HG2HNYRY-U024S51LZSB-ea349f0c4670-512"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-7 text-start mb-4 council-leader-details">
                <div>
                  <div class="fs-3 fw-bold">
                    John <span class="tc-blue">Doe</span>
                  </div>
                  <p class="fs-5">
                    pellendus illum modi nulla laboriosam adipisci quaerat eos
                    maiores autem accusamus possimus?
                  </p>
                  <button class="btn btn-primary w-50">Learn More</button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row" style={contentStyle}>
              <div class="col-md-5 col-sm-12 mb-4">
                <div>
                  <img
                    style={{ height: "400px", width: "400px" }}
                    class="rounded-circle m-4"
                    src="https://ca.slack-edge.com/T01HG2HNYRY-U01JRAWK3PZ-568089e121c4-512"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-md-7 text-start mb-4 council-leader-details">
                <div>
                  <div class="fs-3 fw-bold">
                    John <span class="tc-blue">Doe</span>
                  </div>
                  <p class="fs-5">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Dolore asperiores dicta porro cum,
                  </p>
                  <button class="btn btn-primary w-50">Learn More</button>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div class="my-7">
        <Divider className="tc-blue fs-1 fw-bold ">Our Management</Divider>
        <div class="leader-list">
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=56"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=8"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=12"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=35"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>{" "}
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=44"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>{" "}
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=51"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>{" "}
          <div>
            <img
              class="rounded"
              style={styles.management}
              src="https://i.pravatar.cc/300?img=57"
              alt=""
            />
            <div style={{ fontSize: 30 }}>John Doe</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
