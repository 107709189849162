import { useState } from "react";
import "./KycKickStart.css"; // Assuming you have a CSS file for styles
import { useNavigate } from "react-router-dom";
const KycKickStart = () => {
  const history = useNavigate();
  const [selectedRadio, setSelectedRadio] = useState("IndianResident");
  const [isChecked, setIsChecked] = useState(false);

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const proceedKyc = () => {
    // Add your logic to proceed with KYC
    console.log("Proceeding with KYC");
    history("/d/kyc/steps");
  };

  return (
    <div className="">
      <div className="card rounded-5 text-center">
        <div className="my-3 fw-bold">Let’s kick-start the KYC process</div>
        <div className="text-info fs-6 fw-bold">Details to keep Handy</div>
        <div className="">
          <div className="row my-5">
            <div className="col-md-6 mb-3">
              <div className="d-flex gap-3">
                <div className="card p-3 w-50 bg-info text-light">PAN</div>
                <div className="card p-3 w-50 bg-info text-light">AADHAAR</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex gap-3">
                <div className="w-50">
                  <div className="card p-3 bg-info text-light">
                    Demat Details
                  </div>
                  <div className="fs-6">DP ID, Client ID</div>
                </div>
                <div className="w-50">
                  <div className="card p-3 bg-info text-light">
                    Bank Details
                  </div>
                  <div className="fs-6">IFSC, Account Number</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="fw-bold">
            Before we begin, Please confirm us that you are
          </div>
        </div>
        <div className="my-3 text-center align-self-center">
          <div className="text-center">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div
                  className={`resident-input-container ${
                    selectedRadio === "IndianResident"
                      ? "selected-resident-input"
                      : ""
                  }`}
                >
                  <div className="form-check">
                    <input
                      value="IndianResident"
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="IndianResident"
                      checked={selectedRadio === "IndianResident"}
                      onChange={handleRadioChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="IndianResident"
                    >
                      Indian Resident
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={`resident-input-container ${
                    selectedRadio === "NonIndianResident"
                      ? "selected-resident-input"
                      : ""
                  }`}
                >
                  <div className="form-check">
                    <input
                      value="NonIndianResident"
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="NonIndianResident"
                      checked={selectedRadio === "NonIndianResident"}
                      onChange={handleRadioChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="NonIndianResident"
                    >
                      Non Indian Resident
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3 align-self-center">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              <div>I provide my consent for</div>
              <a href="#terms-and-conditions" className="tc-blue">
                all terms and conditions
              </a>
            </label>
          </div>
        </div>
        <div className="my-5">
          <button
            disabled={!isChecked}
            className="btn btn-primary btn-lg call-button fs-5 tbc-blue"
            type="button"
            onClick={proceedKyc}
          >
            Start KYC Process
          </button>
        </div>
      </div>
    </div>
  );
};

export default KycKickStart;
