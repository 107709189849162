import React, { useState } from 'react';
import { Typography, Table, Card, Button, Modal } from 'antd';
import './modal.css'; // Import custom CSS for styling

const { Title } = Typography;

// Define the columns for the main table
const mainColumns = (handleRowClick) => [
  { title: 'Parameter', dataIndex: 'parameter', key: 'parameter' },
  { title: 'Weightage', dataIndex: 'weightage', key: 'weightage' },
  { title: 'Max Points', dataIndex: 'maxPoints', key: 'maxPoints' },
  { title: 'Sub-items (No)', dataIndex: 'subItems', key: 'subItems' },
  { title: 'Details', dataIndex: 'details', key: 'details' },
  { title: 'Source', dataIndex: 'source', key: 'source' },
];

// Main data for the main table
const mainData = [
  { key: '1', parameter: 'Quality of disclosures', weightage: '30%', maxPoints: '40', subItems: '3', details: 'Quality of Disclosures', source: 'Offer Document/ NSE/BSE' },
  { key: '2', parameter: 'Investors’ perception', weightage: '20%', maxPoints: '20', subItems: '2', details: 'Investor Perception', source: 'CCIL/NSE' },
  { key: '3', parameter: 'Transparency', weightage: '10%', maxPoints: '10', subItems: '1', details: 'Auditors', source: 'Company Website/ Annual Report' },
  { key: '4', parameter: 'Quality of Issuer', weightage: '30%', maxPoints: '20', subItems: '1', details: 'Quality of Issuer', source: 'Offer Document/ SEBI Website' },
  { key: '5', parameter: 'Quality of Intermediaries', weightage: '10%', maxPoints: '10', subItems: '1', details: 'Intermediaries', source: 'Offer Document & PRIME Database' },
  { key: '6', parameter: '', weightage: '100%', maxPoints: '100', subItems: '8', details: '', source: '' },
];

// Define columns for each sub-table
const subTableColumns = (title) => [
  { title, dataIndex: 'item', key: 'item' },
  { title: 'Points', dataIndex: 'points', key: 'points' },
];

// Define data for each sub-table
const subTableData = {
  'Quality of Disclosures': [
    { key: '1', item: 'Off-shore listing (Equity/Debt) & Domestic Listing(Equity/Debt)', points: '40' },
    { key: '2', item: 'Domestic Listings (Equity/Debt)', points: '30' },
    { key: '3', item: 'Private Placement & Public Issue of debt', points: '20' },
    { key: '4', item: 'Private Placement of Debt', points: '10' },
  ],
  'Investor Perception': [
    { key: '1', item: 'At par or below', points: '20' },
    { key: '2', item: 'up to 5 bp', points: '10' },
    { key: '3', item: 'Above 5 bp', points: '0' },
  ],
  'Auditors': [
    { key: '1', item: 'Top 3', points: '10' },
    { key: '2', item: '3-5', points: '5' },
    { key: '3', item: 'Beyond 5', points: '0' },
  ],
  'Quality of Issuer': [
    { key: '1', item: 'Bank/FI/PSU', points: '20' },
    { key: '2', item: 'Private Sector', points: '15' },
    { key: '3', item: 'NBFC', points: '10' },
    { key: '4', item: 'SPV/Other', points: '10' },
  ],
  'Intermediaries': [
    { key: '1', item: 'Top 3', points: '10' },
    { key: '2', item: '3-5', points: '5' },
    { key: '3', item: 'Beyond 5', points: '0' },
  ],
};

const Governance = () => {
  const [visible, setVisible] = useState(null);

  // Function to open the modal
  const handleOpenModal = (title) => {
    setVisible(title);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setVisible(null);
  };

  // Row click handler to open the modal
  const handleRowClick = (record) => {
    handleOpenModal(record.details);
  };

  return (
    <div className="governance-container">
      <Title level={4} className="section-title">
        Corporate Governance Assessment
      </Title>
      
      <Card className="main-table-card">
        <Table
          columns={mainColumns(handleRowClick)}
          dataSource={mainData}
          pagination={false}
          bordered
          className="main-table"
          rowKey="key"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Card>

      {/* Render modals for each sub-table */}
      {Object.keys(subTableData).map((key) => (
        <Modal
          key={key}
          title={key}
          visible={visible === key}
          onCancel={handleCloseModal}
          footer={[
            <Button key="back" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleCloseModal}>
              OK
            </Button>,
          ]}
          width={500}
          style={{ top: 20 }}
        >
          <Table
            columns={subTableColumns(key)}
            dataSource={subTableData[key]}
            pagination={false}
            bordered
            className="sub-table"
          />
        </Modal>
      ))}
    </div>
  );
};

export default Governance;
